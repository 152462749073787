import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from 'locales/pt/translation.json';

i18n.use(initReactI18next).init({
  ns: 'translation',
  fallbackLng: 'pt',
  preload: ['pt'],
  lng: 'pt',
  resources: {
    pt: { translation },
  },
});

export default i18n;
