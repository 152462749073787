import { useContext, useEffect } from 'react';
import { Text, Section, Row, Card, Col, Box } from 'uisos';
import { ProfileContext } from '../../context/ProfileContext';
import isEmpty from '../../helpers/isEmpty';
import { DataCards } from '../DataCards';
import { ModelIcon } from '../ModelIcon';
import getDataFromCustomer from '../../helpers/getDataFromCustomer';
import { CustomerModel } from '../../@types/api';
import CustomLink from '../CustomLink';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Desktop } from './desktop';

export function ProfileModels() {
  const { models, model, customer } = useContext(ProfileContext);
  const isMobile = useMediaQuery(768);

  if (models && models.length > 0 && isMobile) {
    return (
      <Section color="light" style={{ padding: '32px 0', height: '100%' }}>
        <Row>
          {models.map((model) => (
            <Col span={6} key={model.label}>
              <CustomLink to={model.route} style={{ width: '100%', padding: 4 }}>
                <Card style={{ width: '100%', height: 101, padding: 16 }} active>
                  <Box direction="column" width="100%" justify="flex-start">
                    <ModelIcon route={model.route} size={22} />
                    <Text size="s" textAlign="center" style={{ marginTop: 8 }}>
                      {model.label}
                    </Text>
                  </Box>
                </Card>
              </CustomLink>
            </Col>
          ))}
        </Row>
      </Section>
    );
  } else if (model && !isEmpty(model) && customer && model.model && isMobile) {
    const modelData = getDataFromCustomer(customer, model.model as keyof CustomerModel);

    return modelData && (modelData.length || !isEmpty(modelData)) ? (
      <Section color="light" style={{ padding: '32px 0', height: '100%' }}>
        <Row>
          {modelData.length ? (
            modelData.map((data: any, index: number) => (
              <Col span={12} key={index}>
                <DataCards data={data} type={model.model as string} />
              </Col>
            ))
          ) : (
            <Col span={12}>
              <DataCards data={modelData} type={model.model as string} />
            </Col>
          )}
        </Row>
      </Section>
    ) : (
      <Section color="light" style={{ padding: '32px 0', height: '100%' }}>
        <Row></Row>
      </Section>
    );
  } else if (!isMobile) {
    return <Desktop />;
  } else {
    return null;
  }
}
