import { CustomerModel, Steps } from '../@types/api';
import sortByCreateDate from './sortByCreateDate';
import toCamelCase from './toCamelCase';

export default (
  model: {} | {}[],
  setValue: (key: string, value: string | number | unknown) => void,
  step: number,
  customer?: CustomerModel,
  steps?: Steps,
) => {
  if (steps && steps[step] && customer) {
    const fields = steps[step].fields.map(({ name }) => name);
    if (model) {
      if (Array.isArray(model) && (model as []).length) {
        const sortedModel = sortByCreateDate(model);
        // TODO: precisa validar e garantir que é o mesmo model
        Object.entries(sortedModel[model.length - 1]).map(([key, value]) => {
          if (fields.includes(key) && value !== null) setValue(key, value);
        });
      } else {
        Object.entries(model).map(([key, value]) => {
          const fixedValue = typeof value === 'boolean' ? value.toString() : value;
          const fixedKey = toCamelCase(key);
          if (fields.includes(fixedKey) && fixedValue !== null) setValue(fixedKey, fixedValue);
        });
      }
    } else if (steps[step].model === 'customer') {
      Object.entries(customer).map(([key, value]) => {
        let fixedValue = typeof value === 'boolean' ? value.toString() : value;
        const fixedKey = toCamelCase(key);

        if (
          fields.includes(fixedKey) &&
          typeof fixedValue === 'string' &&
          fixedKey.includes('Date')
        ) {
          setValue(fixedKey, new Date(fixedValue as string).toISOString().split('T')[0]);
        } else if (fields.includes(fixedKey) && fixedValue !== null) {
          if (typeof fixedValue === 'boolean') fixedValue = fixedValue.toString();

          setValue(fixedKey, fixedValue);
        } else if (fixedValue === null) {
          setValue(fixedKey, undefined);
        }
      });
    }
  }
};
