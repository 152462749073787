import { Box, Text } from 'uisos';
import { ReactNode } from 'react';

interface ErrorProps {
  children: ReactNode;
}

export function ErrorMessage({ children }: ErrorProps) {
  return (
    <Box width="100%" margin={[16, 0, 8]} justify="center">
      <Text color="red" textAlign="center" size="s">
        {children}
      </Text>
    </Box>
  );
}
