import styled, { css } from 'styled-components';
import { Box, Image } from 'uisos';

interface AvatarProps {
  width: number | string;
  height: number | string;
  alt: string;
  src: string;
}

export const Container = styled(Box)`
  border-radius: 10px;
  background-color: #f4f4f4;
`;

export const Avatar = styled.div<AvatarProps>`
  ${({ width, height, src }) => css`
    width: ${typeof width === 'number' ? width + 'px' : width};
    height: ${typeof height === 'number' ? height + 'px' : height};
    background-image: url(${src});
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `}
`;

export const ItemContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  height: 32px;
  border: 1px solid #dfdfdf;
  display: flex;
  margin-top: 8px;
`;

export const ItemArrContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  height: fit-content;
  border: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
`;

export const TitleArea = styled.div`
  width: 47%;
  border-radius: 5px;
  background-color: #f4f4f4;
  border: 1px solid #dfdfdf;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
`;

export const TitleArrArea = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: #f4f4f4;
  border: 1px solid #dfdfdf;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 16px;
  margin-bottom: 5px;
`;

export const KeyValueArea = styled.div`
  width: 97%;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #f1f1f1;
  background-color: #ffffff;

  align-items: center;
  margin-bottom: 5px;
  border-radius: 6px;
`;

export const KeyArea = styled.div`
  width: 40%;
  border-radius: 5px;
  padding: 5px 16px;
  height: 100%;
`;

export const ValueArea = styled.div`
  background-color: #f7f7f7;

  width: 60%;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  padding: 5px 16px;
`;

export const ContentArea = styled.div`
  width: 53%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
`;

export const ContactContainer = styled.a`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  display: flex;
  margin-top: 8px;
  background-color: #fffdfd;
`;

export const PersonArea = styled.div`
  width: 50%;
  border-radius: 5px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
`;

export const PhoneArea = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
