import { CustomerModel } from '../@types/api';

export default (customer: CustomerModel, model: string, data?: { [key: string]: string }) => {
  if (['conditions', 'allergies', 'contacts'].includes(model) && data) {
    if ('conditions' === model) {
      return customer['conditions'].find(
        ({ name, type }: { [key: string]: string }) => name === data.name && type === data.type,
      );
    } else if ('allergies' === model) {
      return customer['allergies'].find(
        ({ name, type, symptons }: { [key: string]: string }) =>
          name === data.name && type === data.type && symptons === data.symptons,
      );
    } else {
      return customer[model as keyof typeof customer].find(
        ({ telephone, relationship, name }: { [key: string]: string }) =>
          telephone === data.telephone && relationship === data.relationship && name === data.name,
      );
    }
  }
};
