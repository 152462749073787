import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CgPill } from 'react-icons/cg';
import { FaAddressCard, FaHospitalAlt, FaHospitalUser, FaUserMd } from 'react-icons/fa';
import { Text, Title } from 'uisos';
import { Allergy, Condition, QrCodeModel } from '../../@types/api';
import { Section } from '../../data/getSections';
import capitalize from '../../helpers/capitalize';
import getEtCetera from '../../helpers/getEtCetera';
import useMediaQuery from '../../hooks/useMediaQuery';
import { AddressIcon } from '../AddressIcon';
import { AllergyIcon } from '../AllergyIcon';
import { ConditionIcon } from '../ConditionIcon';
import { InfoIcon } from '../InfoIcon';

import {
  ContentArea,
  ItemArrContainer,
  ItemContainer,
  KeyArea,
  KeyValueArea,
  TitleArea,
  TitleArrArea,
  ValueArea,
} from './styles';

interface CardItemProps {
  title: string;
  content: string;
  data?: Allergy | Condition;
  item?: Section['item'];
  model: keyof QrCodeModel['card'];
  color: Section['color'];
}

export function CardItem({ title, content, model, data, color, item }: CardItemProps) {
  const { t } = useTranslation();
  const breakpoint = useMediaQuery(525);
  const tab = useMediaQuery(453);
  const mobile = useMediaQuery(392);

  const getLength = (a: number, b: number, c: number, d: number) => {
    return breakpoint ? (tab ? (mobile ? a : b) : c) : d;
  };

  const isValid = (text: string) =>
    !['created_at', 'updated_at', 'id', 'customer_id', 'card_visibility', item?.title].includes(
      text,
    );

  const Icon = () => {
    if (model === 'allergies' && data) {
      return <AllergyIcon size={18} type={data.type} color={color} />;
    } else if (model === 'conditions' && data) {
      return <ConditionIcon size={18} type={data.type} name={data.name} color={color} />;
    } else if (model === 'medications') {
      return <CgPill size={18} color={color} />;
    } else if (model === 'treatments') {
      return <FaHospitalUser size={18} color={color} />;
    } else if (model === 'doctors') {
      return <FaUserMd size={18} color={color} />;
    } else if (model === 'hospitals') {
      return <FaHospitalAlt size={18} color={color} />;
    } else if (model === 'healthPlans') {
      return <FaAddressCard size={18} color={color} />;
    } else if (model === 'healthData') {
      return <InfoIcon keyName={title} size={18} value={content} color={color} />;
    } else if (model === 'address') {
      return <AddressIcon keyName={title} size={18} color={color} />;
    }
    return null;
  };

  return (
    <>
      {data ? (
        <ItemArrContainer>
          <TitleArrArea>
            <Icon />
            <Title level={6} style={{ marginLeft: 8, fontSize: breakpoint ? 12 : '' }}>
              {getEtCetera(capitalize(t(title)), getLength(25, 30, 35, 40))}
            </Title>
          </TitleArrArea>
          {Object.entries(data).map(([key, value]) => (
            <>
              {value && isValid(key) && (
                <KeyValueArea key={key}>
                  <KeyArea style={{ minHeight: '100%' }}>
                    <Text size={breakpoint ? 'xs' : 's'} weight="semiBold">
                      {capitalize(t(key))}
                    </Text>
                  </KeyArea>
                  <ValueArea>
                    {key.includes('date') ? (
                      <Text size={breakpoint ? 'xs' : 's'}>
                        {moment(value).format('DD/MM/YYYY')}
                      </Text>
                    ) : (
                      <Text size={breakpoint ? 'xs' : 's'}>{capitalize(t(value))}</Text>
                    )}
                  </ValueArea>
                </KeyValueArea>
              )}
            </>
          ))}
        </ItemArrContainer>
      ) : (
        <ItemContainer>
          <TitleArea>
            <Icon />
            <Title level={6} style={{ marginLeft: 8, fontSize: breakpoint ? 12 : '' }}>
              {getEtCetera(capitalize(t(title)), getLength(8, 9, 16, 22))}
            </Title>
          </TitleArea>
          <ContentArea>
            <Text size={breakpoint ? 'xs' : 's'}>
              {getEtCetera(capitalize(t(content)), getLength(12, 16, 22, 26))}
            </Text>
          </ContentArea>
        </ItemContainer>
      )}
    </>
  );
}
