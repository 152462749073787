import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Row, Title, Text } from 'uisos';
import AppBox from '../components/AppBox';
import api from '../services/api';
import { Loading } from './Loading';

export function ConfirmUser() {
  const location = useLocation();
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await api.post(`/user${location.pathname}${location.search}`);
      if (data.status === 201) setConfirmed(true);
    })();
  }, []);

  return (
    <>
      {confirmed ? (
        <Grid as="main" gap={0}>
          <Row full style={{ height: '18vh' }}>
            <Box
              height={'100%'}
              width={'100%'}
              direction="column"
              align="flex-start"
              justify="center"
              padding={[80, 24, 42]}
            >
              <Title level={4} color="blue">
                Pronto!
              </Title>
              <Text size="m">Seu email foi confirmado</Text>
            </Box>
          </Row>
          <Row full style={{ height: '82vh' }}>
            <AppBox
              height={'100%'}
              color="blue"
              width={'100%'}
              padding={[56, 24, 0]}
              align="flex-start"
            ></AppBox>
          </Row>
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
}
