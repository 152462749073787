import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Form, Text, Input } from 'uisos';
import { AuthContext } from '../../context/AuthContext';
import { ErrorMessage } from '../ErrorMessage';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface ResetPasswordFormProps {}

export interface ResetPasswordData {
  password: string;
  confirmPassword: string;
}

export function ResetPasswordForm({}: ResetPasswordFormProps) {
  const { register, handleSubmit } = useForm<ResetPasswordData>();
  const { handleReset, loading, error } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit(handleReset as SubmitHandler<ResetPasswordData>)}>
      <Row style={{ marginBottom: 16 }}>
        <Input
          size="large"
          type="password"
          {...register('password')}
          error={error && error.key === 'password' ? error?.message : ''}
          helper="Digite sua senha com 8 digitos ou mais"
          toggleSuffix={<FaEye />}
          toggleOffSuffix={<FaEyeSlash />}
        >
          Senha
        </Input>
      </Row>
      <Row>
        <Input
          size="large"
          type="password"
          {...register('confirmPassword')}
          toggleSuffix={<FaEye />}
          toggleOffSuffix={<FaEyeSlash />}
        >
          Confirme sua senha
        </Input>
      </Row>
      <Row>
        <ErrorMessage>{error && !error.key ? error?.message : ''}</ErrorMessage>
      </Row>
      <Row>
        <Button type="submit" full size="large" color="secondary" loading={loading}>
          Enviar
        </Button>
      </Row>
    </Form>
  );
}
