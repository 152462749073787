import { useNavigate } from 'react-router-dom';
import { Grid, Row, Box, Title, Text, Image, Button } from 'uisos';
import AppBox from '../components/AppBox';

export function Finished() {
  const navigate = useNavigate();

  const handleShare = async () => {
    const data = {
      title: 'IASOS',
      text: 'Comprei meu adesivo IASOS e agora estou seguro! Compre você também!',
      url: 'https://iasos.com.br',
    };

    await navigator.share(data);
  };

  return (
    <Grid as="main" gap={0} style={{ backgroundColor: '#2F9897', height: '100vh' }}>
      <Row full style={{ height: '100%' }}>
        <Box
          height={'100%'}
          width={'100%'}
          padding={[56, 24, 42]}
          direction="column"
          align="flex-start"
        >
          <Box width={'100%'} justify="center" margin={[0, 0, 24, 0]}>
            <Image
              alt="Logo tipo da iasos em vermelho"
              src="https://images.prismic.io/iasos-test/48c8a07c-a1fa-4db5-bf87-3a67babd267b_logo+branco.png?auto=compress,format"
              width={'50%'}
            />
          </Box>
          <Title level={4} color="white">
            Pronto!
          </Title>
          <Text color="white" size="m">
            O adesivo já está pronto para uso. Agora você pode ficar mais tranquilo!
          </Text>
        </Box>
      </Row>
      <Row full style={{ height: '100%' }}>
        <AppBox
          color="white"
          height={'100%'}
          width={'100%'}
          padding={[56, 0, 32]}
          align="center"
          justify="space-between"
          direction="column"
        >
          <Box justify="flex-end" width={'100%'} margin={[0, 0, 28, 0]} direction="column">
            <Image
              style={{ alignSelf: 'flex-end' }}
              src="https://images.prismic.io/iasos-test/fd96a6bc-9178-4260-9ea8-46e744a5dfa7_Imagem+%284%29.png?auto=compress,format"
              alt="Pessoa escaneando o codigo qr da IASOS no fundo de um outro celular"
              width={'70%'}
            ></Image>
            <Box width={'100%'} padding={[64, 32, 0]}>
              <Text size="s" textAlign="center">
                Avise os seus familiares e amigos que você está usando os adesivos IASOS e ajude-os
                a a ficarem mais seguros!
              </Text>
            </Box>
          </Box>
          <Box width={'100%'} padding={[0, 24, 0]} direction="column">
            <Button
              type="button"
              full
              size="large"
              color="secondary"
              style={{ marginTop: 32 }}
              onClick={handleShare}
            >
              Compartilhar
            </Button>
            <Text
              textAlign="center"
              onClick={() => {
                navigate('/tutorial');
              }}
              underline
              style={{ cursor: 'pointer', marginTop: 16 }}
            >
              Pular
            </Text>
          </Box>
        </AppBox>
      </Row>
    </Grid>
  );
}
