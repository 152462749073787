import { MdMail } from 'react-icons/md';
import {
  FaPlusSquare,
  FaIdCard,
  FaUser,
  FaPhone,
  FaBell,
  FaCity,
  FaPlus,
  FaRoad,
} from 'react-icons/fa';
import { GiDistraction, GiStreetLight } from 'react-icons/gi';
import { BsFillSignpostFill, BsFillSignpostSplitFill } from 'react-icons/bs';
import { AiOutlineFieldNumber } from 'react-icons/ai';
import { RiNumber0 } from 'react-icons/ri';

interface AddressIconProps {
  keyName: string;
  size: number;
  color?: string;
}

export function AddressIcon({ keyName, size, color = '#000' }: AddressIconProps) {
  if (keyName === 'district') {
    return <BsFillSignpostSplitFill size={size} color={color} />;
  } else if (keyName === 'postal_code') {
    return <MdMail size={size} color={color} />;
  } else if (keyName === 'city') {
    return <FaCity size={size} color={color} />;
  } else if (keyName === 'number') {
    return <AiOutlineFieldNumber size={size} color={color} />;
  } else if (keyName === 'state') {
    return <BsFillSignpostFill size={size} color={color} />;
  } else if (keyName === 'street') {
    return <FaRoad size={size} color={color} />;
  } else {
    return <FaPlus size={size} color={color} />;
  }
}
