import { useContext } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Box, Card, Checkbox, Text, Title } from 'uisos';
import { CustomerModel } from '../../@types/api';
import { ModalContext } from '../../context/ModalContext';
import { ProfileContext } from '../../context/ProfileContext';
import { InfoIcon } from '../InfoIcon';
import { useTranslation } from 'react-i18next';
import getEtCetera from '../../helpers/getEtCetera';
import useMediaQuery from '../../hooks/useMediaQuery';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface HealthDataCardProps {
  data: {};
  card: { [key: string]: any };
}

export function HealthDataCard({ data, card }: HealthDataCardProps) {
  const { setCardVisibilityOnHealthData } = useContext(ProfileContext);
  const { t } = useTranslation();
  const breakpoint = useMediaQuery(532);
  const isMobile = useMediaQuery(768);
  const dataList = Object.entries({ ...(data as CustomerModel['healthData']) });
  const fixedHealthData: [string, { value: string; card_visibility: boolean }][] = dataList.map(
    ([key, value]) => [key, { value, card_visibility: card[key] }],
  );

  return (
    <Card style={{ width: '100%' }}>
      {fixedHealthData.map(([key, { value, card_visibility }]) => {
        return (
          <Card.Section key={key} separator>
            <Box align="center" height={57} width={isMobile ? '10%' : '5%'}>
              <InfoIcon keyName={key} value={value} size={isMobile ? 22 : 37} />
            </Box>
            <Box
              align="flex-start"
              direction="column"
              height={64}
              justify="space-evenly"
              width={isMobile ? '40%' : '90%'}
            >
              {key === 'address' ? (
                <Link to="/user/profile/account/address">
                  <Title level={6} color="blue" style={{ textDecoration: 'underline' }} link>
                    {t(key)}
                  </Title>
                </Link>
              ) : (
                <Title level={6}>{t(key)}</Title>
              )}
              <Text size="xs">
                {key.match(/date/i)
                  ? moment(value).format('DD/MM/YYYY')
                  : value || value?.toString()
                  ? getEtCetera(t(value?.toString()), breakpoint ? 12 : 40)
                  : 'Não informado'}
              </Text>
            </Box>
            <Box align="center" justify="space-around" height={57} width="fit-content">
              <Checkbox
                name={key}
                checked={!value && !value?.toString() ? false : !!card_visibility}
                disabled={!value && !value?.toString()}
                onClick={() => setCardVisibilityOnHealthData(key)}
              />
            </Box>
          </Card.Section>
        );
      })}
    </Card>
  );
}
