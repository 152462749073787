import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-touch-drag-slider';
import { Grid, Row, Box, Title, Text, Image, Button } from 'uisos';
import { TutorialContents } from '../components/TutorialContents';
import { TutorialStepDots } from '../components/TutorialStepDots';
import getTutorials from '../data/getTutorials';

export function Tutorial() {
  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate();

  const tutorials = getTutorials();
  return (
    <Grid as="main" gap={0} style={{ height: '100vh' }}>
      <Row full style={{ height: '80vh' }}>
        <Slider
          activeIndex={step}
          onSlideComplete={(i) => {
            setStep(i);
          }}
        >
          {tutorials &&
            tutorials.map((tutorial) => <TutorialContents key={tutorial.title} {...tutorial} />)}
        </Slider>
      </Row>
      <Row full style={{ height: '20vh' }}>
        <Box direction="column" width={'100%'} padding={[16, 24, 32, 24]}>
          <TutorialStepDots step={step} setStep={setStep} steps={tutorials.length} />
          <Box width={'100%'} direction="column" height={113}>
            <Button
              type="button"
              onClick={() => {
                if (step < tutorials.length - 1) {
                  setStep((s) => s + 1);
                } else {
                  navigate('/login/profile');
                }
              }}
              full
              size="large"
              color="secondary"
              style={{ marginTop: 32 }}
            >
              {step < tutorials.length - 1 ? 'Próximo' : 'Acessar o meu perfil'}
            </Button>
            {step < tutorials.length - 1 && (
              <Text
                textAlign="center"
                onClick={() => {
                  step < tutorials.length - 1 && setStep(tutorials.length - 1);
                }}
                aria-hidden="true"
                underline
                style={{ cursor: 'pointer', marginTop: 16, height: 15 }}
              >
                Pular
              </Text>
            )}
          </Box>
        </Box>
      </Row>
    </Grid>
  );
}
