import { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Modal, Text, Title } from 'uisos';
import { ModalContext } from '../../context/ModalContext';
import { ProfileContext } from '../../context/ProfileContext';
import { DataForms } from '../DataForms';
import { AuthContext } from '../../context/AuthContext';

export function Modals() {
  const { type, isOpen, closeModal } = useContext(ModalContext);
  const { add, edit, remove } = useContext(ProfileContext);
  const { setError } = useContext(AuthContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  if (type === 'add') {
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          closeModal();
          setError({});
        }}
      >
        <DataForms onSubmit={add} />
      </Modal>
    );
  } else if (type === 'update') {
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          closeModal();
          setError({});
        }}
      >
        <DataForms onSubmit={edit} />
      </Modal>
    );
  } else if (type === 'info') {
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          closeModal();
          setError({});
        }}
      >
        <Box direction="column">
          <Text
            size="s"
            style={{ marginBottom: 16 }}
            color="blue"
            weight="semiBold"
            textAlign="center"
          >
            Nós atualizamos os nossos serviços, sempre pensando na sua segurança e melhor
            experiência. Atualize a sua senha para prosseguir.
          </Text>
          <Link to={'/forgot?redirect=' + encodeURIComponent(pathname)}>
            <Text
              textAlign="left"
              size="s"
              underline
              style={{ cursor: 'pointer', marginTop: 8 }}
              weight="bold"
            >
              Cadastrar nova senha
            </Text>
          </Link>
          <Text
            size="xs"
            style={{ marginTop: 22, width: '80%' }}
            textAlign="center"
            // color="blue"
            weight="regular"
          >
            Entre em contato com nosso suporte para mais informações:
            <br />
            <br />
            Telefone: (49) 98830-7887
            <br />
            Email: contato@iasos.com.br
          </Text>
        </Box>
      </Modal>
    );
  } else if (type === 'remove') {
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          console.log;
          setError({});
          closeModal();
        }}
      >
        <Box direction="column" width={'100%'}>
          <Title
            level={6}
            style={{ marginBottom: 16, lineHeight: 2 }}
            weight="bold"
            textAlign="center"
          >
            Tem certeza que deseja deletar? <br /> Essa ação não poderá ser desfeita.
          </Title>
          <Box width={'100%'} justify="space-between">
            <Button
              light
              full
              type="button"
              size="large"
              color="secondary"
              onClick={() => closeModal()}
              style={{ marginTop: 32, width: '47%' }}
            >
              Cancelar
            </Button>
            <Button
              full
              type="button"
              size="large"
              color="secondary"
              onClick={() => {
                remove(), closeModal();
              }}
              style={{ marginTop: 32, width: '47%' }}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }
  return null;
}
