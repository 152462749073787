import moment from 'moment';
import { useContext } from 'react';
import { FaPen } from 'react-icons/fa';
import { IoMedicalSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { Box, Row, Text, Title } from 'uisos';
import { CardContext } from '../../context/CardContext';
import Circle from '../Circle';

import { EditButton } from './styles';

export function CardHeader() {
  const { cardData } = useContext(CardContext);

  return (
    <>
      <Row full style={{ backgroundColor: '#F54041' }} justify="space-between">
        <Box padding={24} align="center" width={'100%'}>
          <Title level={4} weight="bold" color="white" style={{ width: '60%' }}>
            Em caso de emergência
          </Title>
          <Circle height={54} width={54} justify="center" align="center" margin={[0, 10]}>
            <IoMedicalSharp color="#F54041" size={40} />
          </Circle>
        </Box>
      </Row>
      <Row>
        <Box
          padding={[24, 24, 0]}
          align="center"
          height={'fit-content'}
          width={'100%'}
          justify="space-between"
        >
          <Box width="fit-content">
            <Text size="s">Atualizado em: {moment(cardData.lastUpdate).format('DD/MM/YYYY')}</Text>
          </Box>
          <Box width="fit-content">
            <Link to="/login/profile">
              <EditButton>
                Editar
                <div>
                  <FaPen size={12} color={'#2F9897'} />
                </div>
              </EditButton>
            </Link>
          </Box>
        </Box>
      </Row>
    </>
  );
}
