import { CSSProperties, useContext } from 'react';
import { FaHome } from 'react-icons/fa';
import { IoClose, IoMenu } from 'react-icons/io5';
import { MdLogout } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { Box, Text, Title } from 'uisos';
import { AuthContext } from '../../context/AuthContext';
import getModels from '../../data/getModels';
import CustomLink from '../CustomLink';
import { ModelIcon } from '../ModelIcon';
import { IconBg, MenuBoxDesktop, ModelLink, ModelLinks, SubModelLink } from './styles';

interface MenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function Desktop({ open, setOpen }: MenuProps) {
  const models = getModels();
  const { handleLogout } = useContext(AuthContext);
  const { pathname } = useLocation();

  const href = (route: string) => {
    return '/user/profile/' + route;
  };

  const styleOnOpen = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 370,
  } as CSSProperties;

  return (
    <MenuBoxDesktop
      as="aside"
      // width="fit-content"
      height="100vh"
      direction="column"
      justify="flex-start"
      className={open ? 'open' : ''}
    >
      <Box
        width="100%"
        height="100%"
        direction="column"
        padding={[30, 24]}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          overflowY: open ? 'auto' : 'hidden',
          overflowX: 'hidden',
        }}
      >
        <Box width="100%" height="fit-content" justify="flex-end" margin={[0, 0, 80, 0]}>
          <IconBg>
            {open ? (
              <IoClose
                size={42}
                onClick={() => setOpen(!open)}
                aria-hidden="true"
                color={'#8E8E8E'}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <IoMenu
                size={24}
                onClick={() => setOpen(!open)}
                aria-hidden="true"
                color={'#8E8E8E'}
                style={{ cursor: 'pointer' }}
              />
            )}
          </IconBg>
        </Box>
        <Box width="100%" height="fit-content" align="flex-start" margin={[0, 0, 96]}>
          <ModelLinks>
            <ModelLink>
              <CustomLink reloadDocument to={'/user/profile'} style={styleOnOpen}>
                <IconBg className={'/user/profile' === pathname ? 'selected' : ''}>
                  <FaHome size={24} color={'#8E8E8E'} />
                </IconBg>
                {open && (
                  <Title
                    level={6}
                    weight={'/user/profile' === pathname ? 'bold' : 'semiBold'}
                    color={'/user/profile' === pathname ? 'black' : 'disabled'}
                    style={{ margin: '0 0 0 24px' }}
                  >
                    Início
                  </Title>
                )}
              </CustomLink>
            </ModelLink>
            {models?.map((model) => (
              <Box width="100%" direction="column" key={model.route} align="flex-start">
                <ModelLink>
                  <CustomLink reloadDocument to={href(model.route)} style={styleOnOpen}>
                    <IconBg
                      className={
                        open
                          ? href(model.route) === pathname
                            ? 'selected'
                            : ''
                          : pathname.includes(model.route)
                          ? 'selected'
                          : ''
                      }
                    >
                      <ModelIcon route={model.route} size={24} color={'#8E8E8E'} />
                    </IconBg>
                    {open && (
                      <Title
                        level={6}
                        weight={href(model.route).includes(pathname) ? 'bold' : 'semiBold'}
                        color={href(model.route).includes(pathname) ? 'black' : 'disabled'}
                        style={{ margin: '0 0 0 24px' }}
                      >
                        {model.label}
                      </Title>
                    )}
                  </CustomLink>
                </ModelLink>

                {open &&
                  model?.models?.map((submodel) => (
                    <SubModelLink key={submodel.title}>
                      <CustomLink
                        reloadDocument
                        to={href(`${model.route}/${submodel.route}`)}
                        style={styleOnOpen}
                      >
                        <IconBg
                          className={
                            href(`${model.route}/${submodel.route}`) === pathname ? 'selected' : ''
                          }
                        >
                          <ModelIcon route={submodel.route} size={24} color={'#8E8E8E'} />
                        </IconBg>
                        <Title
                          level={6}
                          style={{ margin: '0 0 0 24px' }}
                          weight={
                            href(`${model.route}/${submodel.route}`) === pathname
                              ? 'bold'
                              : 'semiBold'
                          }
                          color={
                            href(`${model.route}/${submodel.route}`) === pathname
                              ? 'black'
                              : 'disabled'
                          }
                        >
                          {submodel.label}
                        </Title>
                      </CustomLink>
                    </SubModelLink>
                  ))}
              </Box>
            ))}
            <ModelLink onClick={handleLogout} style={{ marginTop: 94, ...styleOnOpen }}>
              <IconBg className={'/login/profile' === pathname ? 'selected' : ''}>
                <MdLogout size={open ? 42 : 24} color={open ? '#C1333D' : '#8E8E8E'} />
              </IconBg>
              {open && (
                <Title level={6} weight="bold" style={{ margin: '0 0 0 24px' }}>
                  Sair
                </Title>
              )}
            </ModelLink>
          </ModelLinks>
        </Box>
      </Box>
    </MenuBoxDesktop>
  );
}
