import { IoMdWater } from 'react-icons/io';
import { RiScales2Fill } from 'react-icons/ri';
import { MdCake } from 'react-icons/md';
import { GiBodyHeight } from 'react-icons/gi';
import {
  FaTint,
  FaSmoking,
  FaSmokingBan,
  FaHeart,
  FaPlusSquare,
  FaIdCard,
  FaCalendar,
  FaBirthdayCake,
  FaUser,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import { BiFemaleSign, BiMaleSign } from 'react-icons/bi';

interface InfoIconProps {
  keyName: string;
  value: string;
  size: number;
  color?: string;
}

export function InfoIcon({ keyName, value, size, color = '#000' }: InfoIconProps) {
  if (keyName === 'blood_type') {
    return <FaTint size={size} color={color} />;
  } else if (keyName === 'organ_donor') {
    return <FaHeart size={size} color={color} />;
  } else if (keyName === 'smoker') {
    if (value) {
      return <FaSmoking size={size} color={color} />;
    } else {
      return <FaSmokingBan size={size} color={color} />;
    }
  } else if (keyName === 'sus') {
    return <FaIdCard size={size} color={color} />;
  } else if (keyName === 'height') {
    return <GiBodyHeight size={size} color={color} />;
  } else if (keyName === 'weight') {
    return <RiScales2Fill size={size} color={color} />;
  } else if (keyName === 'birth_date') {
    return <FaBirthdayCake size={size} color={color} />;
  } else if (keyName === 'full_name') {
    return <FaUser size={size} color={color} />;
  } else if (keyName === 'sex') {
    if (value === 'male') {
      return <BiMaleSign size={size} color={color} />;
    } else {
      return <BiFemaleSign size={size} color={color} />;
    }
  } else if (keyName === 'address') {
    return <FaMapMarkerAlt size={size} />;
  } else {
    return <FaPlusSquare size={size} color={color} />;
  }
}
