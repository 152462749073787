import { useContext, useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Box, Card, Checkbox, Text, Title } from 'uisos';
import { CustomerModel } from '../../@types/api';
import { ModalContext } from '../../context/ModalContext';
import { ProfileContext } from '../../context/ProfileContext';
import { useTranslation } from 'react-i18next';
import getEtCetera from '../../helpers/getEtCetera';
import useMediaQuery from '../../hooks/useMediaQuery';

interface ContactCardProps {
  data: {};
}

export function ContactCard({ data }: ContactCardProps) {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const [nameLenght, setNameLength] = useState<number>(30);
  const { setId, remove, setCardVisibility } = useContext(ProfileContext);
  const { name, card_visibility, id, relationship, telephone } =
    data as unknown as CustomerModel['contacts'][0];
  const isMobile = useMediaQuery(768);

  return (
    <Card variant="middle" style={{ width: '100%' }}>
      <Card.Main separator padding={15}>
        <Box
          align="flex-start"
          direction="column"
          height={'fit-content'}
          justify="space-between"
          width="60%"
          onClick={() => setNameLength((e) => (e === 30 ? 100 : 30))}
        >
          <Title level={5} style={{ marginBottom: 16 }}>
            {getEtCetera(name, nameLenght)}
          </Title>
          <Text size="xs" style={{ marginBottom: 16 }}>
            {t(relationship)}
          </Text>
          <Text size="s">{telephone}</Text>
        </Box>
        <Box align="center" justify="space-around" height={57} width="25%">
          <FaPen
            size={isMobile ? 18 : 24}
            aria-hidden="true"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openModal('update'), setId(id);
            }}
          />
          <FaTrash
            aria-hidden="true"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openModal('remove'), setId(id);
            }}
            size={isMobile ? 18 : 24}
          />
        </Box>
      </Card.Main>
      <Card.Footer padding={[8, 18]}>
        <Checkbox checked={card_visibility} name="visibility" onClick={() => setCardVisibility(id)}>
          Visibilidade no cartão IASOS
        </Checkbox>
      </Card.Footer>
    </Card>
  );
}
