import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { getCookie } from '../helpers/cookies';
import { Loading } from '../pages';

export default function AuthRoute() {
  const activationToken = getCookie('activation_token');
  const { authenticated, loading, error } = useContext(AuthContext);
  const { pathname } = useLocation();
  const paths = ['/login', '/register', '/'];

  if (
    (!activationToken && !pathname.includes('profile') && !pathname.includes('validation')) ||
    (!authenticated && !activationToken && !pathname.includes('profile'))
  ) {
    return <Navigate to="/login/profile" />;
  }

  if (activationToken && !authenticated && !paths.includes(pathname) && error && error.message) {
    return <Navigate to={pathname} />;
  }

  if (pathname === '/login/profile' && authenticated) {
    return <Navigate to={'/user/profile'} />;
  }

  if (pathname === '/login/profile' && !authenticated && loading) {
    return <Loading />;
  }

  if (pathname.includes('/user/profile') && !authenticated && !loading) {
    return <Navigate to={'/login/profile'} />;
  }

  if (pathname.includes('/user/profile') && !authenticated && loading) {
    return <Loading />;
  }

  return <Outlet />;
}
