import { Box, Grid, Row, Title, Text } from 'uisos';
import AppBox from '../components/AppBox';
import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import { FormPage } from '../components/FormPage';
import useMediaQuery from '../hooks/useMediaQuery';

export function ForgotPassword() {
  return (
    <Grid as="main" gap={0} style={{ height: '100vh' }}>
      <FormPage
        title={
          <Title level={4} color="blue">
            Esqueceu sua senha?
          </Title>
        }
        subtitle={
          <Text size="m">Digite seu email e enviaremos um link para recuperação da sua conta</Text>
        }
      >
        <ForgotPasswordForm />
      </FormPage>
    </Grid>
  );
}
