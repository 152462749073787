import { createContext, ReactNode } from 'react';
import { CustomerModel, Step } from '../@types/api';
import useStep from '../hooks/useStep';

interface StepProviderProps {
  children: ReactNode;
}

interface StepContextType {
  loading?: boolean;
  error?: { [key: string]: any };
  steps?: Step[];
  step: number;
  customer?: CustomerModel;
  setSteps: (steps: Step[]) => void;
  setStep: (step: number) => void;
  nextStep: () => void;
  previousStep: () => void;
  skip: () => void;
  addAnother: (data: object) => void;
  complete: () => void;
  removeOnStep: (route: string, model: string, id: string) => void;
  model?: any;
}

const StepContext = createContext<StepContextType>({
  setSteps: (steps: Step[]) => {},
  setStep: (step: number) => {},
  nextStep: () => null,
  previousStep: () => null,
  skip: () => null,
  addAnother: (data: object) => null,
  complete: () => null,
  removeOnStep: (route: string, model: string, id: string) => null,
  step: 0,
});

function StepProvider({ children }: StepProviderProps) {
  const {
    steps,
    step,
    loading,
    error,
    setSteps,
    setStep,
    nextStep,
    previousStep,
    customer,
    skip,
    model,
    addAnother,
    complete,
    removeOnStep,
  } = useStep();

  return (
    <StepContext.Provider
      value={{
        steps,
        step,
        loading,
        error,
        setSteps,
        setStep,
        nextStep,
        previousStep,
        customer,
        skip,
        model,
        addAnother,
        complete,
        removeOnStep,
      }}
    >
      {children}
    </StepContext.Provider>
  );
}

export { StepContext, StepProvider };
