import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useForceUpdate from './useForceUpdate';

export type ModalType = 'add' | 'update' | 'info' | 'remove' | '';

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [type, setType] = useState<ModalType>('');
  const { pathname } = useLocation();

  useEffect(() => {
    setValue((value) => value + 1);
  }, [isOpen, type]);

  useEffect(() => {
    closeModal();
  }, [pathname]);

  const openModal = (modalType: ModalType) => {
    setType(modalType);
    setIsOpen(true);
  };

  const closeModal = () => {
    setType('');
    setIsOpen(false);
  };

  return {
    isOpen,
    type,
    openModal,
    closeModal,
  };
};
