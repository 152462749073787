import { createContext, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { ObjectLiteralElementLike } from 'typescript';
import { CustomerModel } from '../@types/api';
import { Model } from '../data/getModels';
import useProfile from '../hooks/useProfile';

interface ProfileContextType {
  customer?: CustomerModel;
  loading?: boolean;
  models?: Model[];
  model?: Model;
  error: { key: string; message: string };
  id?: string;
  card?: { [key: string]: boolean };
  edit: (data: object) => void;
  add: (data: object) => void;
  remove: () => void;
  setId: (id: string) => void;
  setError: (error: any) => void;
  setCardVisibility: (id: string) => void;
  setCardVisibilityOnHealthData: (key: string) => void;
  updateAvatar: (avatar: any) => void;
  updateNotification: () => void;
}

const ProfileContext = createContext<ProfileContextType>({
  error: { key: '', message: '' },
  edit: (data: object) => null,
  add: (data: object) => null,
  remove: () => null,
  setId: (id: string) => null,
  setError: (error: any) => null,
  setCardVisibility: (id: string) => null,
  setCardVisibilityOnHealthData: (key: string) => null,
  updateAvatar: (avatar: any) => null,
  updateNotification: () => null,
});

function ProfileProvider() {
  const {
    customer,
    loading,
    models,
    model,
    error,
    id,
    card,
    edit,
    add,
    remove,
    setId,
    setError,
    setCardVisibility,
    setCardVisibilityOnHealthData,
    updateAvatar,
    updateNotification,
  } = useProfile();

  return (
    <ProfileContext.Provider
      value={{
        customer,
        loading,
        models,
        model,
        error,
        id,
        card,
        edit,
        add,
        remove,
        setId,
        setError,
        setCardVisibility,
        setCardVisibilityOnHealthData,
        updateAvatar,
        updateNotification,
      }}
    >
      <Outlet />
    </ProfileContext.Provider>
  );
}

export { ProfileContext, ProfileProvider };
