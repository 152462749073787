export default () => [
  {
    alt: 'Image of main menu of iasos for desktop',
    src: 'https://images.prismic.io/iasos-test/a1775125-eed7-4941-be78-fbd8c1b95723_Imagem+%287%29.png?auto=compress,format',
    title: 'A IASOS tá de cara nova!',
    text: 'Agora você pode ter todas as suas informações de saúde na palma da mão.',
  },
  {
    alt: 'Image of many models of iasos for desktop',
    src: 'https://images.prismic.io/iasos-test/933d3f17-35ee-4aaf-a6cb-0ec3b7abb3d4_Imagem+%289%29.png?auto=compress,format',
    title: 'Sua informações de saúde em um só lugar',
    text: 'Registre as suas alergias, doenças, medicamentos e muito mais!',
  },
  {
    alt: 'Image of two forms in iasos for desktop',
    src: 'https://images.prismic.io/iasos-test/342414b2-740b-42eb-b920-b3df289fef2d_Imagem+%2810%29.png?auto=compress,format',
    title: 'Informações editáveis',
    text: 'Altere suas informações quantas vezes quiser através da nossa plataforma 100% responsiva. ',
  },
  {
    alt: 'Image of personal data model on iasos for desktop',
    src: 'https://images.prismic.io/iasos-test/f5cb6585-63da-4689-83e5-93d0a8d84b41_Imagem+%2811%29.png?auto=compress,format',
    title: 'Mais autonomia',
    text: 'Você pode ativar quais informações deseja deixar visível no seu CARD IASOS. ',
  },
  {
    alt: 'Image of an "ok" sign',
    src: 'https://images.prismic.io/iasos-test/3acd2543-ed23-4b2c-b892-045e8c890894_Imagem+%2812%29.png?auto=compress,format',
    title: 'É simples e rápido!',
    text: 'Agora você já pode complementar e gerenciar todas as suas informações de saúde.',
  },
];
