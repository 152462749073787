import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { Steps } from '../@types/api';
import { StepData } from '../components/StepForm';
import alreadyExistsIn from '../helpers/alreadyExistsIn';
import { deleteCookie, setCookie } from '../helpers/cookies';
import fixData from '../helpers/fixData';
import api from '../services/api';
import fetcher from '../services/fetcher';

export default () => {
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState([] as Steps);
  const [step, setStep] = useState(0);
  const [error, setError] = useState<{ [key: string]: any }>({});
  const [model, setModel] = useState<any>();
  const [mounted, setMounted] = useState(false);

  const { data: customer, mutate: mutateCustomer } = useSWR(mounted ? '/customer' : null, fetcher);

  const navigate = useNavigate();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (steps && steps[step] && customer) {
      const newModel = customer[steps[step].model as keyof typeof customer];

      if (
        !model ||
        !newModel ||
        (Array.isArray(model) && !Array.isArray(newModel)) ||
        (!Array.isArray(model) && Array.isArray(newModel)) ||
        (Array.isArray(model) && Array.isArray(newModel) && model.length !== newModel.length) ||
        (Array.isArray(model) &&
          Array.isArray(newModel) &&
          newModel.length &&
          model.length &&
          newModel[0].id !== model[0].id)
      ) {
        setModel(newModel);
      }
    }
    setLoading(false);
  }, [step, steps, customer, model]);

  const finish = async () => {
    await api.patch('/link/complete');
    await api.patch('/customer/finish');

    deleteCookie('activation_token');
    deleteCookie('step');

    navigate('/register/finished');
  };

  const nextStep = async (data?: StepData) => {
    setError({});
    setLoading(true);

    const formData = new FormData();
    const method = steps[step].method || 'post';
    const model = steps[step].model;
    const customerData = customer[steps[step].model];
    const dataIsEmpty = data && !Object.values(data).filter((d) => d).length;

    try {
      if (data?.avatar) {
        formData.append('avatar', data.avatar[0]);
        if (data.avatar[0].size > 5242880) {
          setError({
            message: 'File size not accepted, please choose an image with 5MB or less',
            key: 'avatar',
          });
          setLoading(false);
        }
      }

      if (
        (Array.isArray(customerData) && alreadyExistsIn(customer, steps[step].model, data)) ||
        (Array.isArray(customerData) && customerData.length && dataIsEmpty)
      ) {
        setStep(step + 1);
        setCookie('step', (step + 1).toString(), 1);
        setError({});
        setLoading(false);
        return null;
      }

      await api[method](steps[step].route, data?.avatar ? formData : data);

      if (model === 'customer') {
        mutateCustomer({ ...customer, ...data });
      } else {
        mutateCustomer({ ...customer, [model]: data });
      }

      await skip();
    } catch (error) {
      handleError(error);
    }
  };

  const skip = async () => {
    if (step === steps.length - 1) {
      await finish();
    }

    setStep(step + 1);
    setCookie('step', (step + 1).toString(), 1);
    setError({});
    setLoading(false);
  };

  const previousStep = () => {
    if (step > 0) {
      setStep(step - 1);
      setCookie('step', (step - 1).toString(), 1);
      setError({});
    }
  };

  const complete = async () => {
    const link: { complete: boolean } = await api.get('/link');

    if (!link.complete) {
      await api.patch('/link/complete');
      await api.patch('/customer/finish');

      deleteCookie('auth_token');
      deleteCookie('activation_token');
      deleteCookie('step');
    }
  };

  const addAnother = async (data: StepData) => {
    setError({});
    try {
      await api.post(steps[step].route, data);

      await mutateCustomer({
        ...customer,
        [steps[step].model]: [...customer[steps[step].model], data],
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error: unknown) => {
    if (error instanceof AxiosError) {
      const data: AxiosResponse['data'] = error.response?.data;

      if (data.validation) {
        setError({ message: data.validation.body.message, key: data.validation.body.keys[0] });
      } else {
        setError(data);
      }
    } else if (error instanceof Error) {
      setError(error);
    }
    setLoading(false);
  };

  const removeOnStep = async (route: string, model: string, id: string) => {
    setLoading(true);
    try {
      if (model && id) {
        await api.delete(`${route}/${id}`);

        const index = customer[model].findIndex((e: any) => e.id === id);

        await mutateCustomer({
          ...customer,
          [model]: [...customer[model].slice(0, index), ...customer[model].slice(index + 1)],
        });
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  return {
    nextStep,
    step,
    loading,
    steps,
    error,
    previousStep,
    setSteps,
    setStep,
    customer,
    skip,
    model,
    complete,
    addAnother,
    removeOnStep,
  };
};
