import { Box } from 'uisos';

interface TutorialStepDotsProps {
  steps: number;
  setStep: (step: number) => void;
  step: number;
}

export function TutorialStepDots({ steps, step, setStep }: TutorialStepDotsProps) {
  return (
    <Box>
      {Array.from(Array(steps).keys()).map((s, index) => (
        <div
          key={s}
          style={{
            borderRadius: '100%',
            width: 16,
            height: 16,
            backgroundColor: step !== index ? '#dfdfdf' : '',
            margin: '0 8px',
            cursor: 'pointer',
            backgroundImage:
              step === index ? 'linear-gradient(2.95deg, #FF6464 2.66%, #FF8E8E 97.78%)' : '',
          }}
          aria-hidden="true"
          onClick={() => setStep(index)}
        />
      ))}
    </Box>
  );
}
