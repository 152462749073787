import { useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { CardContext } from '../context/CardContext';
import { deleteCookie, setCookie } from '../helpers/cookies';
import { Loading } from '../pages';
import api from '../services/api';
import fetcher from '../services/fetcher';

interface AuthQrCodeProps {
  children: JSX.Element;
}

export default function AuthQrCode({ children }: AuthQrCodeProps) {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const { data: qrCodeData } = useSWR(`/link/${params.qr_code}/${params.salt}`, fetcher);
  const { setCardData } = useContext(CardContext);

  useEffect(() => {
    if (qrCodeData) {
      setLoading(false);
      setCardData(qrCodeData);
    }
  }, [qrCodeData, setCardData]);

  if (!loading) {
    if (!qrCodeData.complete) {
      deleteCookie('activation_token');
      deleteCookie('auth_token');
      deleteCookie('step');

      setCookie('activation_token', qrCodeData.token, 1);
      api.defaults.headers['activate'] = qrCodeData.token;
      return <Navigate to={'/'} />;
    } else {
      return children;
    }
  }

  return <Loading />;
}
