import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button, Row, Form, Text, Input, Checkbox } from 'uisos';
import { AuthContext } from '../../context/AuthContext';
import mask from '../../helpers/masks';
import { ErrorMessage } from '../ErrorMessage';

interface RegisterFormProps {}

export interface RegisterData {
  email: string;
  fullName: string;
  cpf: string;
  telephone: string;
  password: string;
  confirmPassword: string;
  notification: boolean;
  terms: boolean;
  privacy: boolean;
}

export function RegisterForm({}: RegisterFormProps) {
  const { register, handleSubmit, watch, setValue } = useForm<RegisterData>();
  const { handleRegister, loading, error } = useContext(AuthContext);

  const phoneValue = watch('telephone');
  const cpfValue = watch('cpf');

  useEffect(() => {
    setValue('telephone', mask(phoneValue, 'telephone'));
    setValue('cpf', mask(cpfValue, 'cpf'));
  }, [phoneValue, cpfValue]);

  return (
    <Form
      onSubmit={handleSubmit(handleRegister as SubmitHandler<RegisterData>)}
      style={{ marginBottom: 32 }}
    >
      <Row>
        <Input
          size="large"
          error={error && error.key === 'fullName' ? error?.message : ''}
          {...register('fullName')}
          placeholder="Nome Completo"
        >
          Nome Completo
        </Input>
      </Row>
      <Row>
        <Input
          size="large"
          error={error && error.key === 'cpf' ? error?.message : ''}
          {...register('cpf')}
          placeholder="CPF"
          inputMode="numeric"
        >
          CPF
        </Input>
      </Row>
      <Row>
        <Input
          size="large"
          error={error && error.key === 'telephone' ? error?.message : ''}
          {...register('telephone')}
          placeholder="Telefone"
          inputMode="tel"
        >
          Telefone
        </Input>
      </Row>
      <Row>
        <Input
          size="large"
          error={error && error.key === 'email' ? error?.message : ''}
          {...register('email')}
          placeholder="Email"
        >
          Email
        </Input>
      </Row>
      <Row>
        <Input
          size="large"
          type="password"
          {...register('password')}
          error={error && error.key === 'password' ? error?.message : ''}
          helper="Digite sua senha com 8 digitos ou mais"
          toggleSuffix={<FaEye />}
          toggleOffSuffix={<FaEyeSlash />}
        >
          Senha
        </Input>
      </Row>
      <Row>
        <Input
          size="large"
          type="password"
          {...register('confirmPassword')}
          toggleSuffix={<FaEye />}
          toggleOffSuffix={<FaEyeSlash />}
        >
          Confirme sua senha
        </Input>
      </Row>
      <Row>
        <Checkbox left={false} {...register('terms')}>
          Aceito os
          <a href="/">
            <Text as="span" color="red">
              {` `}termos e condições de uso
            </Text>
          </a>
        </Checkbox>
      </Row>
      <Row>
        <Checkbox left={false} {...register('privacy')}>
          Aceito a política de privacidade
        </Checkbox>
      </Row>
      <Row>
        <Checkbox left={false} {...register('notification')}>
          Desejo receber promoções e novidades por email
        </Checkbox>
      </Row>
      <Row>
        <ErrorMessage>{error && !error.key ? error?.message : ''}</ErrorMessage>
      </Row>
      <Row>
        <Button
          type="submit"
          full
          size="large"
          color="secondary"
          style={{ marginTop: 32 }}
          loading={loading}
        >
          Criar conta
        </Button>
      </Row>
      <Link to="/login">
        <Text textAlign="center" underline style={{ cursor: 'pointer', marginTop: 16 }}>
          Já tenho conta cadastrada
        </Text>
      </Link>
    </Form>
  );
}
