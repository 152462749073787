import {
  Allergy,
  Condition,
  Contact,
  Doctor,
  HealthPlan,
  Hospital,
  Medication,
  QrCodeModel,
  Treatment,
} from '../@types/api';

export default (): Sections => [
  {
    title: 'Endereço',
    model: 'address',
    color: '#F54041',
    item: {
      title: '',
      content: 'brand',
    },
  },
  {
    title: 'Alergias e reações',
    model: 'allergies',
    color: '#F54041',
    item: {
      title: 'name',
      content: 'symptons',
    },
  },
  {
    title: 'Contato de emergência',
    subtitle: 'Ligue agora!',
    model: 'contacts',
    item: {
      title: 'name',
      content: 'relationship',
      telephone: 'telephone',
    },
  },
  {
    title: 'Condições de saúde',
    model: 'conditions',
    color: '#2F9897',
    item: {
      title: 'name',
      content: 'name',
    },
  },
  {
    title: 'Medicamentos',
    model: 'medications',
    color: '#F54041',
    item: {
      title: 'name',
      content: 'dose',
    },
  },
  {
    title: 'Tratamentos',
    model: 'treatments',
    color: '#F54041',
    item: {
      title: 'name',
      content: 'observations',
    },
  },
  {
    title: 'Hospitais',
    model: 'hospitals',
    color: '#2F9897',
    item: {
      title: 'name',
      content: 'address',
    },
  },
  {
    title: 'Médicos',
    model: 'doctors',
    color: '#2F9897',
    item: {
      title: 'name',
      content: 'telephone',
    },
  },
  {
    title: 'Planos de saúde',
    model: 'healthPlans',
    color: '#2F9897',
    item: {
      title: 'name',
      content: 'number',
    },
  },
  {
    title: 'Informações básicas',
    model: 'healthData',
    color: '#2F9897',
    item: {
      title: '',
      content: 'address',
    },
  },
];

export type Section = {
  title: string;
  subtitle?: string;
  model: keyof QrCodeModel['card'];
  color?: '#F54041' | '#2F9897';
  item: {
    title:
      | keyof Allergy
      | keyof Medication
      | keyof Treatment
      | keyof HealthPlan
      | keyof Doctor
      | keyof Hospital
      | keyof Condition
      | keyof Contact
      | '';
    content:
      | keyof Allergy
      | keyof Medication
      | keyof Treatment
      | keyof HealthPlan
      | keyof Doctor
      | keyof Hospital
      | keyof Condition
      | keyof Contact;
    telephone?: keyof Contact;
  };
};

type Sections = Section[];
