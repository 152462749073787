import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Row, Form, Text, Input, Checkbox, Box } from 'uisos';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';
import { ErrorMessage } from '../ErrorMessage';
import ReactLoading from 'react-loading';
import { deleteCookie, getCookie, setCookie } from '../../helpers/cookies';
import mask from '../../helpers/masks';

interface ValidationFormProps {}

export interface ValidationData {
  code: string;
}

export function ValidationForm({}: ValidationFormProps) {
  const { register, handleSubmit, setValue, watch } = useForm<ValidationData>();
  const [counter, setCounter] = useState(
    getCookie('resend-counter') ? +getCookie('resend-counter') : 0,
  );
  const { handleValidation, loading, error, resendCode, resending } = useContext(AuthContext);
  const codeValue = watch('code');

  useEffect(() => {
    if (resending) {
      setCookie('resend-counter', 30, 1);
      setCounter(30);
    }
  }, [resending]);

  useEffect(() => {
    setValue('code', mask(codeValue, 'code'));
  }, [codeValue]);

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
        counter === 1
          ? deleteCookie('resend-counter')
          : setCookie('resend-counter', counter - 1, 1);
      }, 1000);
    return () => (timer ? clearInterval(timer) : undefined);
  }, [counter]);

  return (
    <Form onSubmit={handleSubmit(handleValidation as SubmitHandler<ValidationData>)}>
      <Row>
        <Input
          size="large"
          {...register('code')}
          placeholder="Código"
          type="number"
          error={error && error.key === 'code' ? error?.message : null}
          helper="Insira o código de validação enviado para seu email"
        >
          Código
        </Input>
      </Row>
      <Row>
        <ErrorMessage>{error && !error.key ? error?.message : null}</ErrorMessage>
      </Row>
      <Row>
        <Button type="submit" full size="large" color="secondary" loading={loading}>
          Validar
        </Button>
      </Row>
      {counter > 0 ? (
        <Text textAlign="center" style={{ marginTop: 16 }} color="disabled">
          00:{counter < 10 ? `0${counter}` : counter}
        </Text>
      ) : (
        <Button
          type="button"
          onClick={resendCode}
          full
          style={{ backgroundColor: 'transparent', border: 'none', outline: 'none' }}
        >
          {resending ? (
            <Box margin={[5, 0, 0, 0]} width="fit-content" height="fit-content">
              <ReactLoading type="spin" color="#000" width={16} height={'100%'} />
            </Box>
          ) : (
            <Text textAlign="center" underline style={{ cursor: 'pointer', marginTop: 16 }}>
              Não recebi o email
            </Text>
          )}
        </Button>
      )}
    </Form>
  );
}
