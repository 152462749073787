/* eslint-disable turbo/no-undeclared-env-vars */
export const setCookie = (cookie: string, value: string | number, days: number) => {
  const cookiesAllowed = localStorage.getItem('cookieConsent');

  if (cookiesAllowed === 'false') {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const item = {
      value,
      expires: d ? d : null,
    };
    localStorage.setItem(cookie, JSON.stringify(item));
  } else {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    let expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cookie}=${value};${expires};path=/;${
      process.env.REACT_APP_DOMAIN ? 'domain=' + process.env.REACT_APP_DOMAIN : ''
    }`;
  }
};

export const getCookie = (cookie: string) => {
  const cookiesAllowed = localStorage.getItem('cookieConsent');

  if (cookiesAllowed === 'false') {
    const storedItem = localStorage.getItem(cookie);

    if (storedItem) {
      const parsedItem = JSON.parse(storedItem);
      if (parsedItem.expires && parsedItem.expires < Date.now()) {
        localStorage.removeItem(cookie);
        return null;
      }
      return parsedItem.value;
    }
    return null;
  } else {
    let name = `${cookie}=`;
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
};

export const deleteCookie = (cookie: string) => {
  if (getCookie(cookie)) {
    localStorage.removeItem(cookie);
    document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
};
