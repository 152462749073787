import { Box, Text, Title } from 'uisos';
import { MdPhoneInTalk } from 'react-icons/md';

import { PhoneArea, PersonArea } from './styles';
import shortName from '../../helpers/shortName';
import { ContactContainer } from './styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../hooks/useMediaQuery';

interface ContactItemProps {
  name: string;
  relationship: string;
  telephone: string;
}

export function ContactItem({ name, relationship, telephone }: ContactItemProps) {
  const { t } = useTranslation();
  const breakpoint = useMediaQuery(380);
  return (
    <ContactContainer href={`tel:${telephone}`}>
      <PersonArea style={{ wordWrap: 'break-word' }}>
        <MdPhoneInTalk size={48} color="#F54041" />
        <Box direction="column" align="flex-start" width="70%" style={{ wordWrap: 'break-word' }}>
          <Text>{t(relationship)}</Text>
          <Text size="xs" weight="bold">
            {shortName(name)}
          </Text>
        </Box>
      </PersonArea>
      <PhoneArea>
        <Text size={'xs'} color="blue">
          {telephone}
        </Text>
      </PhoneArea>
    </ContactContainer>
  );
}
