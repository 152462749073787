import styled, { css } from 'styled-components';
import { Box } from 'uisos';

export type AppBoxProps = {
  color?: 'blue' | 'white';
};

export default styled(Box)<AppBoxProps>`
  ${({ color = 'blue' }) => css`
    border-radius: 15px 15px 0 0;
    background-color: ${color === 'blue' ? '#e6f0f0' : '#FFFDFD'};
    overflow: auto;
  `}
`;
