import { ReactNode } from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, Paragraph } from 'uisos';
import useMediaQuery from '../../hooks/useMediaQuery';

interface CookieAdProps {}

export function CookieAd({}: CookieAdProps) {
  const [showAd, setShowAd] = useState(false);
  const breakpoiint = useMediaQuery(837);
  const mobile = useMediaQuery(550);

  useEffect(() => {
    const localStorageConsent = localStorage.getItem('cookieConsent');
    if (!localStorageConsent) {
      setShowAd(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowAd(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setShowAd(false);
  };

  if (!showAd) {
    return null;
  }

  return (
    <div
      className="cookie-ad"
      style={{
        position: 'fixed',
        left: '16px',
        bottom: '16px',
        width: mobile ? '90%' : breakpoiint ? '50%' : '25%',
        maxWidth: '90%',
        borderRadius: '8px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.08)',
        padding: '16px',
        zIndex: '9999',
      }}
    >
      <Paragraph style={{ fontSize: '16px' }}>
        Nós utilizamos cookies para melhorar a sua experiência em nosso site. Ao continuar
        navegando, você concorda com o uso de cookies.
      </Paragraph>
      <Box width={'100%'} margin={[12, 0, 0, 0]}>
        <Button onClick={handleAccept} full color="secondary" style={{ marginRight: 8 }}>
          Aceitar
        </Button>
        <Button onClick={handleDecline} full color="secondary" light style={{ marginLeft: 8 }}>
          Recusar
        </Button>
      </Box>
    </div>
  );
}

export default CookieAd;
