import { useEffect } from 'react';
import { Step } from '../@types/api';
import { StepFormProps } from '../components/StepForm';
import mask from '../helpers/masks';

export default (
  watch: StepFormProps['watch'],
  setValue: StepFormProps['setValue'],
  fields: Step['fields'],
) => {
  const phoneValue = watch('telephone') as unknown as string;
  const rgValue = watch('rg') as unknown as string;
  const susValue = watch('sus') as unknown as string;
  const heightValue = watch('height') as unknown as string;
  const weightValue = watch('weight') as unknown as string;

  const fieldExist = (fieldName: string) => fields.find((f) => f.name === fieldName);

  useEffect(() => {
    if (phoneValue && fieldExist('telephone')) setValue('telephone', mask(phoneValue, 'telephone'));
    if (rgValue && fieldExist('rg')) setValue('rg', mask(rgValue, 'rg'));
    if (susValue && fieldExist('sus')) setValue('sus', mask(susValue, 'sus'));
    if (heightValue && fieldExist('height')) setValue('height', mask(heightValue, 'height'));
    if (weightValue && fieldExist('weight')) setValue('weight', mask(weightValue, 'height'));
  }, [phoneValue, rgValue, susValue, heightValue, weightValue]);
};
