import { MdMail } from 'react-icons/md';
import { FaPlusSquare, FaIdCard, FaUser, FaPhone, FaBell, FaIdBadge } from 'react-icons/fa';

interface UserIconProps {
  keyName: string;
  size: number;
  color?: string;
}

export function UserIcon({ keyName, size, color = '#000' }: UserIconProps) {
  if (keyName === 'full_name') {
    return <FaUser size={size} color={color} />;
  } else if (keyName === 'cpf') {
    return <FaIdCard size={size} color={color} />;
  } else if (keyName === 'rg') {
    return <FaIdBadge size={size} color={color} />;
  } else if (keyName === 'email') {
    return <MdMail size={size} color={color} />;
  } else if (keyName === 'telephone') {
    return <FaPhone size={size} color={color} />;
  } else if (keyName === 'notification') {
    return <FaBell size={size} color={color} />;
  } else {
    return <FaPlusSquare size={size} color={color} />;
  }
}
