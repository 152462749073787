import { useContext } from 'react';
import { Box, Title, Text } from 'uisos';
import { CardContext } from '../../context/CardContext';
import shortName from '../../helpers/shortName';
import { IoMdWater } from 'react-icons/io';
import { RiScales2Fill } from 'react-icons/ri';
import { MdCake } from 'react-icons/md';
import { GiBodyHeight } from 'react-icons/gi';
import { Avatar, Container } from './styles';
import useMediaQuery from '../../hooks/useMediaQuery';

export function CardHero() {
  const breakpoint = useMediaQuery(472);
  const {
    cardData: {
      card: {
        healthData: { blood_type, weight, height },
        fullName,
        avatar,
        age,
      },
    },
  } = useContext(CardContext);

  return (
    <Container padding={[22, breakpoint ? 24 : 42, 18]} width={'100%'} justify="space-between">
      <Avatar
        width={breakpoint ? 88 : 104}
        height={breakpoint ? 88 : 104}
        alt={'avatar'}
        src={`${process.env.REACT_APP_API_BASE_URL}/files/${avatar || process.env.DEFAULT_AVATAR}`}
      ></Avatar>
      <Box
        direction="column"
        align="flex-start"
        margin={[0, 0, 0, breakpoint ? 0 : 18]}
        width="60%"
      >
        {fullName ? <Title level={6}>{shortName(fullName)}</Title> : null}
        <Box width={'100%'} style={{ flexWrap: 'wrap' }} margin={[18, 0, 0, 0]}>
          {blood_type ? (
            <Box width={'50%'} margin={[0, 0, 18, 0]} align="flex-start" justify="flex-start">
              <IoMdWater color="#F54041" style={{ marginRight: 6 }} />
              <Text size="xs">{blood_type}</Text>
            </Box>
          ) : null}
          {typeof age === 'number' ? (
            <Box width={'50%'} margin={[0, 0, 18, 0]} align="flex-start" justify="flex-start">
              <MdCake color="#767676" style={{ marginRight: 6 }} />
              <Text size="xs">
                {age} ano{age === 1 ? '' : 's'}
              </Text>
            </Box>
          ) : null}
          {weight ? (
            <Box width={'50%'} margin={[0, 0, 18, 0]} align="flex-start" justify="flex-start">
              <RiScales2Fill color="#767676" style={{ marginRight: 6 }} />
              <Text size="xs">{weight} kg</Text>
            </Box>
          ) : null}
          {height ? (
            <Box width={'50%'} margin={[0, 0, 18, 0]} align="flex-start" justify="flex-start">
              <GiBodyHeight color="#767676" style={{ marginRight: 6 }} />
              <Text size="xs">{height / 100} m</Text>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Container>
  );
}
