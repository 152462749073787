import { Box, Row } from 'uisos';
import ReactLoading from 'react-loading';

export function Loading() {
  return (
    <Row style={{ height: '100vh' }} justify="center" align="center">
      <Box>
        <ReactLoading type="bubbles" color="#269898" width={58} height={'100%'} />
      </Box>
    </Row>
  );
}
