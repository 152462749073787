import { FaSpider, FaAllergies, FaPaw } from 'react-icons/fa';
import { CgPill } from 'react-icons/cg';
import { GiDustCloud, GiRubberBoot, GiMushroom, GiMeal } from 'react-icons/gi';

interface AllergyIconProps {
  type: string;
  size: number;
  color?: string;
}

export function AllergyIcon({ type, size, color = '#000' }: AllergyIconProps) {
  if (type === 'insect') {
    return <FaSpider size={size} color={color} />;
  } else if (type === 'drug') {
    return <CgPill size={size} color={color} />;
  } else if (type === 'pet') {
    return <FaPaw size={size} color={color} />;
  } else if (type === 'pollen') {
    return <GiDustCloud size={size} color={color} />;
  } else if (type === 'latex') {
    return <GiRubberBoot size={size} color={color} />;
  } else if (type === 'mold') {
    return <GiMushroom size={size} color={color} />;
  } else if (type === 'food') {
    return <GiMeal size={size} color={color} />;
  } else {
    return <FaAllergies size={size} color={color} />;
  }
}
