export const maskTelephone = (value: string) => {
  const text = value.includes('+55 ')
    ? value.replace('+55 ', '')
    : value.includes('+55')
    ? value.replace('+55', '')
    : value;

  const newText = text
    .replace(/[\D]/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d)/, '$1');

  return '+55 ' + newText;
};

export const maskCpf = (value: string) => {
  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})(\d)/, '$1');
};

export const maskRg = (value: string) => {
  return value.replace(/[\D]/g, '').replace(/(\d{14})(\d)/, '$1');
};

export const maskPostalCode = (value: string) => {
  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})(\d)/, '$1');
};

export const maskSus = (value: string) => {
  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{3})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/( \d{4})(\d)/, '$1');
};

export const maskCode = (value: string) => {
  return value.replace(/(\d{4})(\d)/, '$1');
};

export const maskHeight = (value: string) => {
  return value
    .toString()
    .replace(/[\D]/g, '')
    .replace(/(\d{3})(\d)/, '$1');
};

const mask = (
  value: string | undefined,
  name: 'telephone' | 'cpf' | 'postalCode' | 'rg' | 'sus' | 'code' | 'height',
) => {
  if (!value) return '';

  switch (name) {
    case 'telephone':
      return maskTelephone(value);
    case 'cpf':
      return maskCpf(value);
    case 'postalCode':
      return maskPostalCode(value);
    case 'rg':
      return maskRg(value);
    case 'sus':
      return maskSus(value);
    case 'code':
      return maskCode(value);
    case 'height':
      return maskHeight(value);
    default:
      return '';
  }
};

export default mask;
