import {
  FaSpider,
  FaAllergies,
  FaPaw,
  FaPlusSquare,
  FaUserEdit,
  FaRegPlusSquare,
  FaHospitalUser,
  FaUserMd,
  FaHospitalAlt,
  FaBookmark,
  FaUser,
  FaMapMarkerAlt,
  FaNotesMedical,
} from 'react-icons/fa';
import { CgPill } from 'react-icons/cg';
import { GiDustCloud, GiRubberBoot, GiMushroom, GiMeal } from 'react-icons/gi';
import { MdBlock, MdHealthAndSafety, MdPhoneInTalk } from 'react-icons/md';
import { RiHealthBookFill } from 'react-icons/ri';

interface ModelIconProps {
  route: string;
  size: number;
  color?: string;
}

export function ModelIcon({ route, size, color = '#000000' }: ModelIconProps) {
  if (route === 'contact') {
    return <MdPhoneInTalk size={size} color={color} />;
  } else if (route === 'preferences') {
    return <RiHealthBookFill size={size} color={color} />;
  } else if (route === 'condition') {
    return <FaRegPlusSquare size={size} color={color} />;
  } else if (route === 'info') {
    return <FaNotesMedical size={size} color={color} />;
  } else if (route === 'allergy') {
    return <MdBlock size={size} color={color} />;
  } else if (route === 'treatment') {
    return <FaHospitalUser size={size} color={color} />;
  } else if (route === 'medication') {
    return <CgPill size={size} color={color} />;
  } else if (route === 'doctor') {
    return <FaUserMd size={size} color={color} />;
  } else if (route === 'hospital') {
    return <FaHospitalAlt size={size} color={color} />;
  } else if (route === 'healthPlan') {
    return <MdHealthAndSafety size={size} color={color} />;
  } else if (route === 'user') {
    return <FaUser size={size} color={color} />;
  } else if (route === 'address') {
    return <FaMapMarkerAlt size={size} color={color} />;
  } else if (route === 'account') {
    return <FaUserEdit size={size} color={color} />;
  } else {
    return <FaPlusSquare size={size} color={color} />;
  }
}
