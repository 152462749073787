import { Route, Routes } from 'react-router-dom';
import { Modals } from '../components/Modals';
import { AuthProvider } from '../context/AuthContext';
import { CardProvider } from '../context/CardContext';
import { ModalProvider } from '../context/ModalContext';
import { ProfileProvider } from '../context/ProfileContext';
import { StepProvider } from '../context/StepContext';
import { IasosCard, Finished, Home, Loading, Login, NotFound, Register, Profile } from '../pages';
import { ConfirmUser } from '../pages/ConfirmUser';
import { ForgotPassword } from '../pages/ForgotPassword';
import { ProfileModel } from '../pages/ProfileModel';
import { ProfileSubmodel } from '../pages/ProfileSubmodel';
import { RegisterStep } from '../pages/RegisterStep';
import { ResetPassword } from '../pages/ResetPassword ';
import { Tutorial } from '../pages/Tutorial';
import { Validation } from '../pages/Validation';
import AuthQrCode from './AuthQrCode';
import AuthRoute from './AuthRoute';
import StepRoute from './StepRoute';

export default function MainRoutes() {
  return (
    <Routes>
      <Route
        path="/profile/:qr_code/:salt"
        element={
          <CardProvider>
            <AuthQrCode>
              <IasosCard />
            </AuthQrCode>
          </CardProvider>
        }
      />
      <Route
        path="/forgot"
        element={
          <AuthProvider>
            <ForgotPassword />
          </AuthProvider>
        }
      />
      <Route path="/confirm" element={<ConfirmUser />} />
      <Route
        path="/reset"
        element={
          <AuthProvider>
            <ResetPassword />
          </AuthProvider>
        }
      />
      <Route
        element={
          <AuthProvider>
            <AuthRoute />
          </AuthProvider>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/validation" element={<Validation />} />
        <Route
          path="/register/step"
          element={
            <StepProvider>
              <StepRoute>
                <RegisterStep />
              </StepRoute>
            </StepProvider>
          }
        />
        <Route path="/login/profile" element={<Login />} />
        <Route element={<ProfileProvider></ProfileProvider>}>
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/user/profile/:model" element={<ProfileModel />} />
          <Route path="/user/profile/:model/:submodel" element={<ProfileSubmodel />} />
          <Route path="/register/finished" element={<Finished />} />
          <Route path="/tutorial" element={<Tutorial />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
