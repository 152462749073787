import { useContext, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { StepContext } from '../context/StepContext';
import { getCookie, setCookie } from '../helpers/cookies';
import { Loading } from '../pages';
import fetcher from '../services/fetcher';

interface StepRouteProps {
  children: JSX.Element;
}

export default function StepRoute({ children }: StepRouteProps) {
  const { data, error } = useSWR('/steps', fetcher);
  const { setSteps, setStep, complete: completeLink } = useContext(StepContext);

  useEffect(() => {
    if (data) {
      const { steps, actual } = data;
      if (typeof actual === 'number' && steps) {
        setSteps(steps);

        if (getCookie('step')) {
          setStep(+getCookie('step'));
        } else {
          setStep(actual);
          setCookie('step', actual, 1);
        }
      }
    }
  }, [data, setSteps, setStep]);

  if (data) {
    const { steps, actual, complete } = data;

    if (typeof actual === 'number' && steps) {
      return children;
    } else if (complete) {
      completeLink();
      return <Navigate to={'/register/finished'} />;
    }
  }

  return <Loading />;
}
