import { useContext, useEffect, useState } from 'react';
import { Box, Title, Text, Section, Row, Col, Button, Input, FileInput } from 'uisos';
import { CardContext } from '../../context/CardContext';
import shortName from '../../helpers/shortName';
import { IoMdWater } from 'react-icons/io';
import { RiScales2Fill } from 'react-icons/ri';
import { MdCake } from 'react-icons/md';
import { GiBodyHeight } from 'react-icons/gi';
import { Avatar } from './styles';
import { ProfileContext } from '../../context/ProfileContext';
import { FaCamera } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../hooks/useMediaQuery';
import ReactLoading from 'react-loading';

export function Desktop() {
  const [avatar, setAvatar] = useState<any>();
  const { t } = useTranslation();
  const { customer, updateAvatar, error, setError, loading } = useContext(ProfileContext);

  useEffect(() => {
    if (avatar && avatar[0]) {
      updateAvatar(avatar);
    }
  }, [avatar]);

  useEffect(() => {
    if (error && error.message && error.key === 'avatar') {
      alert(t(error.message));
      setAvatar(null);
      setError({ message: '', key: '' });
    }
  }, [error]);

  return customer ? (
    <Section color="light" style={{ padding: '32px 0 0', marginLeft: 88 }}>
      <Row justify="flex-start" style={{ height: 'fit-content' }}>
        <Box width="fit-content" height="fit-content" style={{ position: 'relative' }}>
          {loading ? (
            <Box width={151} height={151} margin={[0, 0, 20, 0]} justify="center" align="center">
              <ReactLoading type="spin" color="#269898" width={'50%'} height={'50%'} />
            </Box>
          ) : (
            <Avatar
              name={'avatar'}
              onChange={({ target }) => setAvatar(target.files)}
              icon={<FaCamera size={32} className="icon" />}
              file={
                avatar
                  ? avatar[0]
                  : `${process.env.REACT_APP_API_BASE_URL}/files/${
                      customer.avatar || process.env.DEFAULT_AVATAR
                    }`
              }
            />
          )}
        </Box>
        <Box
          direction="column"
          margin={[0, 0, 0, 18]}
          height="initial"
          width="fit-content"
          justify="space-around"
        >
          <Title level={5}>{shortName(customer.full_name)}</Title>
          <Box width={'100%'} style={{ flexWrap: 'wrap' }}>
            {customer.healthData?.blood_type ? (
              <Box width={'50%'} margin={[0, 0, 18, 0]} align="center" justify="flex-start">
                <IoMdWater size={24} style={{ marginRight: 6 }} />
                <Text size="xs">{customer?.healthData?.blood_type}</Text>
              </Box>
            ) : null}
            {typeof customer.age === 'number' ? (
              <Box width={'50%'} margin={[0, 0, 18, 0]} align="center" justify="flex-start">
                <MdCake size={28} style={{ marginRight: 6 }} />
                <Text size="xs">
                  {customer.age} ano{customer.age === 1 ? '' : 's'}
                </Text>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Row>
    </Section>
  ) : null;
}
