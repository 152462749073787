import { ReactNode } from 'react';
import { Box, Image, Text, Title } from 'uisos';

interface TutorialContentsProps {
  alt: string;
  src: string;
  title: string;
  text: string;
}

export function TutorialContents({ alt, src, text, title }: TutorialContentsProps) {
  return (
    <Box width={'100%'} direction="column" align="center" margin={[89, 0, 0, 0]} height="100%">
      <Image alt={alt} src={src} width={'100%'} />
      <Title level={4} color="blue" textAlign="center" style={{ padding: '32px 32px 16px' }}>
        {title}
      </Title>
      <Text size="m" textAlign="center" style={{ padding: '0 32px' }}>
        {text}
      </Text>
    </Box>
  );
}
