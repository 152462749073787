import { useContext } from 'react';
import { Grid } from 'uisos';
import { CardContext } from '../context/CardContext';
import isEmpty from '../helpers/isEmpty';
import { Loading } from './Loading';
import { CardData } from '../components/CardData';

import { CardHeader } from '../components/CardHeader';

export function IasosCard() {
  const { cardData } = useContext(CardContext);

  return (
    <>
      {isEmpty(cardData) ? (
        <Loading />
      ) : (
        <Grid as="main" gap={0} style={{ padding: '0 0 96px 0' }}>
          <CardHeader />
          <CardData />
        </Grid>
      )}
    </>
  );
}
