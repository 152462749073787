import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Grid, Row, Title, Text, StepBar, Form, Col, Button } from 'uisos';
import AppBox from '../components/AppBox';
import { Modals } from '../components/Modals';
import { StepDataList } from '../components/StepDataList';
import { StepData, StepForm } from '../components/StepForm';
import { StepContext } from '../context/StepContext';
import fieldsExist from '../helpers/fieldsExist';
import isEmpty from '../helpers/isEmpty';
import modelExist from '../helpers/modelExist';
import populateForm from '../helpers/populateForm';
import { Loading } from './Loading';

export function RegisterStep() {
  const { step, steps, previousStep, error, nextStep, customer, skip, model, addAnother, loading } =
    useContext(StepContext);
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    formState: { isSubmitSuccessful },
  } = useForm<StepData>();

  useEffect(() => {
    if ((model && (model.length || !isEmpty(model))) || model === undefined) {
      populateForm(model, setValue, step, customer, steps);
    } else {
      reset();
    }
  }, [customer, setValue, steps, step, model]);

  useEffect(() => {
    window.scrollTo(0, 0);
    reset();
  }, [step]);

  return (
    <Grid as="main" gap={0} style={{ height: '100vh' }}>
      <Modals />
      {loading || !(steps && steps[step] && customer) ? (
        <Loading />
      ) : (
        <>
          <Row full style={{ height: '100%' }}>
            <Box height={'100%'} width={'100%'} direction="column" padding={[0, 0, 16, 0]}>
              <Box width={'100%'} direction="column" align="flex-start" padding={[42, 24, 42]}>
                <Title level={4} color="blue">
                  {steps[step].title}
                </Title>
                <Text size="m">{steps[step].description}</Text>
              </Box>
              <StepBar steps={steps} actual={step + 1} />
            </Box>
          </Row>
          {/* <Row full style={{ maxHeight: '50%', marginTop: 16 }}>
          </Row> */}
          <Row full style={{ height: '100%' }}>
            <AppBox
              style={{ minHeight: '100%' }}
              color="blue"
              width={'100%'}
              padding={[24]}
              align="flex-start"
              justify="flex-start"
              direction="column"
            >
              <StepDataList model={model} />
              <Form
                onSubmit={handleSubmit(nextStep as SubmitHandler<StepData>)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: '100%',
                }}
              >
                <StepForm
                  setValue={setValue}
                  step={steps[step]}
                  error={error}
                  watch={watch}
                  register={register}
                />
                {steps[step].multiple && (
                  <Row full justify="flex-end">
                    <Text
                      onClick={handleSubmit(addAnother as SubmitHandler<StepData>)}
                      underline
                      style={{ cursor: 'pointer', marginTop: 16 }}
                    >
                      Adicionar outro
                    </Text>
                  </Row>
                )}
                <Row>
                  <Col span={6} style={{ paddingRight: 16 }}>
                    <Button
                      type="button"
                      full
                      size="large"
                      light
                      color="secondary"
                      disabled={step === 0}
                      style={{ marginTop: 32 }}
                      onClick={async () => {
                        reset();
                        previousStep();
                      }}
                    >
                      Voltar
                    </Button>
                  </Col>
                  <Col span={6} style={{ paddingLeft: 16 }}>
                    <Button
                      type="submit"
                      full
                      size="large"
                      color="secondary"
                      style={{ marginTop: 32 }}
                    >
                      {!steps[step + 1] ? 'Finalizar' : 'Continuar'}
                    </Button>
                  </Col>
                  {!steps[step].required ||
                  modelExist(model) ||
                  fieldsExist(steps[step], customer) ? (
                    <Col span={12}>
                      <Text
                        textAlign="center"
                        onClick={() => {
                          reset();
                          skip();
                        }}
                        underline
                        style={{ cursor: 'pointer', marginTop: 16 }}
                      >
                        Pular
                      </Text>
                    </Col>
                  ) : null}
                </Row>
              </Form>
            </AppBox>
          </Row>
        </>
      )}
    </Grid>
  );
}
