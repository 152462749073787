import { CustomerModel } from '../../@types/api';
import { useContext } from 'react';
import { ProfileContext } from '../../context/ProfileContext';
import { HospitalCard } from './HospitalCard';
import { ContactCard } from './ContactCard';
import { DoctorCard } from './DoctorCard';
import { HealthDataCard } from './HealthDataCard';
import { ConditionCard } from './ConditionCard';
import { AllergyCard } from './AllergyCard';
import { MedicationCard } from './MedicationCard';
import { TreatmentCard } from './TreatmentCard';
import { HealthPlanCard } from './HealthPlanCard';
import { UserAddressCard } from './UserAddressCard';

interface DataCardsProps {
  data:
    | CustomerModel['allergies'][0]
    | CustomerModel['contacts'][0]
    | CustomerModel['conditions'][0]
    | CustomerModel['doctors'][0]
    | CustomerModel['hospitals'][0]
    | CustomerModel['healthData'];
  type: 'allergies' | 'contacts' | 'conditions' | string;
}

export function DataCards({ data, type }: DataCardsProps) {
  const { card } = useContext(ProfileContext);

  // quando type for healthData gerar tambem um card, mas que gera seções automaticamente por meio de Object.entries(data)
  // cada linha gerada deve ter um radio com uma função onClik changeCardVisibility({ object.entrie[0]: true/false  })
  // descobrir como pegar esse true or false, lembrar da api card

  if (type === 'allergies') {
    return <AllergyCard data={data} />;
  } else if (type === 'conditions') {
    return <ConditionCard data={data} />;
  } else if (type === 'contacts') {
    return <ContactCard data={data} />;
  } else if (type === 'healthData' && card) {
    return <HealthDataCard data={data} card={card} />;
  } else if (type === 'doctors') {
    return <DoctorCard data={data} />;
  } else if (type === 'hospitals') {
    return <HospitalCard data={data} />;
  } else if (type === 'medications') {
    return <MedicationCard data={data} />;
  } else if (type === 'treatments') {
    return <TreatmentCard data={data} />;
  } else if (type === 'healthPlans') {
    return <HealthPlanCard data={data} />;
  } else if (type === 'user' || type === 'address') {
    return <UserAddressCard data={data} type={type} />;
  }
  return null;
}
