export default (): Models => [
  {
    route: 'info',
    label: 'Básico',
    model: 'healthData',
    title: 'Informações básicas',
    type: 'object',
    description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
    // icon: <FaUserEdit />,
    fields: [
      {
        name: 'fullName',
        label: 'Nome Completo',
        type: 'text',
        placeholder: 'Nome Completo',
        outer: true,
      },
      {
        name: 'birthDate',
        label: 'Data de Nascimento',
        type: 'date',
        placeholder: 'Data de Nascimento',
        outer: true,
      },
      {
        name: 'bloodType',
        label: 'Tipo sanguineo',
        type: 'select',
        options: [
          { label: 'A+', value: 'A+' },
          { label: 'A-', value: 'A-' },
          { label: 'B+', value: 'B+' },
          { label: 'B-', value: 'B-' },
          { label: 'O+', value: 'O+' },
          { label: 'O-', value: 'O-' },
          { label: 'AB+', value: 'AB+' },
          { label: 'AB-', value: 'AB-' },
        ],
        placeholder: 'Tipo',
      },
      {
        name: 'organDonor',
        label: 'Você é doador de orgãos?',
        type: 'radio',
        radios: [
          {
            value: 'true',
            left: false,
            label: 'Sim',
          },
          {
            value: 'false',
            left: false,
            label: 'Não',
          },
          {
            value: '',
            left: false,
            label: 'Não informar',
          },
        ],
      },
      {
        name: 'smoker',
        label: 'Você é fumante?',
        type: 'radio',
        radios: [
          {
            value: 'true',
            left: false,
            label: 'Sim',
          },
          {
            value: 'false',
            left: false,
            label: 'Não',
          },
          {
            value: '',
            left: false,
            label: 'Não informar',
          },
        ],
      },
      {
        type: 'number',
        name: 'height',
        label: 'Altura',
        min: 0,
        max: 300,
        step: 1,
        error: 'Altura inválida',
        placeholder: 'Em cm',
        helper: 'Ex: 180',
      },
      {
        type: 'number',
        name: 'weight',
        label: 'Peso',
        min: 0,
        max: 300,
        step: 1,
        error: 'Peso inválido',
        placeholder: 'Em kg',
        helper: 'Ex: 80',
      },
      {
        name: 'sex',
        label: 'Sexo',
        type: 'select',
        options: [
          {
            label: 'Masculino',
            value: 'male',
          },
          {
            label: 'Feminino',
            value: 'female',
          },
        ],
        placeholder: 'Sexo',
      },
      {
        label: 'Sus',
        name: 'sus',
        type: 'text',
        placeholder: 'Número do Cartão',
      },
    ],
  },
  {
    route: 'health',
    label: 'Saúde',
    title: 'Informações de saúde',
    description: 'Te ajudamos na prevenção da sua saúde através da gestão das suas informações.',
    // icon: <FaPlusSquare />,
    models: [
      {
        route: 'allergy',
        label: 'Alergias',
        model: 'allergies',
        title: 'Alergias',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'Nome',
            name: 'name',
            type: 'text',
            placeholder: 'Tenho alergia à',
            helper: 'Ex: Lactose, Glúten',
            required: true,
          },
          {
            label: 'Tipo',
            name: 'type',
            type: 'select',
            required: true,
            placeholder: 'Tipo',
            options: [
              {
                label: 'Medicamentos',
                value: 'drug',
              },
              {
                label: 'Animais',
                value: 'pet',
              },
              {
                label: 'Insetos',
                value: 'insect',
              },
              {
                label: 'Poeira, Pó, Polén...',
                value: 'pollen',
              },
              {
                label: 'Látex, Borracha...',
                value: 'latex',
              },
              {
                label: 'Fungos, Mofo...',
                value: 'mold',
              },
              {
                label: 'Alimentos',
                value: 'food',
              },
              {
                label: 'Outros',
                value: 'other',
              },
            ],
          },
          {
            label: 'Sintomas',
            name: 'symptons',
            type: 'textArea',
            placeholder: 'Sintomas',
          },
        ],
      },
      {
        route: 'condition',
        model: 'conditions',
        label: 'Condições de Saúde',
        title: 'Condições de Saúde',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'Nome',
            name: 'name',
            type: 'text',
            placeholder: 'Nome da condição',
            helper: 'Ex: Hipertensão, Diabetes...',
            required: true,
          },
          {
            label: 'Tipo',
            name: 'type',
            type: 'select',
            required: true,
            placeholder: 'Tipo',
            options: [
              {
                label: 'Comorbidade',
                value: 'comorbity',
              },
              {
                label: 'Doença',
                value: 'disease',
              },
              {
                label: 'Deficiencia',
                value: 'deficiency',
              },
              {
                label: 'Sindrome',
                value: 'syndrome',
              },
            ],
          },
        ],
      },
      {
        route: 'medication',
        label: 'Medicações',
        model: 'medications',
        title: 'Medicações',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'Nome',
            name: 'name',
            type: 'text',
            placeholder: 'Nome do medicamento',
            helper: 'Ex: Ritalina, Rivotril...',
            required: true,
          },
          {
            label: 'Causa',
            name: 'cause',
            type: 'text',
            placeholder: 'Causa',
          },
          {
            label: 'Dose',
            name: 'dose',
            type: 'text',
            helper: 'ex: 3mg, de 8 em 8 horas',
            placeholder: 'Dose',
          },
          {
            label: 'Marca',
            name: 'brand',
            type: 'text',
            placeholder: 'Marca',
          },
          {
            label: 'Substância',
            name: 'substance',
            type: 'text',
            placeholder: 'Substância',
          },
          {
            type: 'range',
            name: 'period',
            label: 'Período',
            fields: [
              {
                label: 'Início',
                name: 'startdate',
                type: 'date',
                placeholder: 'dd/mm/yyyy',
              },
              {
                label: 'Final',
                name: 'enddate',
                type: 'date',
                placeholder: 'dd/mm/yyyy',
              },
            ],
          },
          {
            label: 'Observações',
            name: 'observations',
            type: 'textArea',
            placeholder: 'Observações',
          },
        ],
      },
      {
        route: 'treatment',
        label: 'Tratamentos',
        model: 'treatments',
        title: 'Tratamentos',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'Nome',
            name: 'name',
            type: 'text',
            placeholder: 'Nome do tratamento',
            helper: 'Ex: Quimioterapia, Hemodiálise',
            required: true,
          },
          {
            label: 'Tipo',
            name: 'type',
            type: 'text',
            placeholder: 'Tipo',
          },
          {
            label: 'Causa',
            name: 'cause',
            type: 'text',
            placeholder: 'Causa',
          },
          {
            type: 'range',
            name: 'period',
            label: 'Período',
            fields: [
              {
                label: 'Início',
                name: 'startdate',
                type: 'date',
                placeholder: 'dd/mm/yyyy',
              },
              {
                label: 'Final',
                name: 'enddate',
                type: 'date',
                placeholder: 'dd/mm/yyyy',
              },
            ],
          },
          {
            label: 'Observações',
            name: 'observations',
            type: 'textArea',
            placeholder: 'Observações',
          },
        ],
      },
    ],
  },
  {
    route: 'preferences',
    label: 'Preferências',
    title: 'Preferências de contato',
    description: 'Te ajudamos na prevenção da sua saúde através da gestão das suas informações.',
    models: [
      {
        route: 'doctor',
        label: 'Médicos',
        model: 'doctors',
        title: 'Médicos',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'Nome',
            name: 'name',
            type: 'text',
            placeholder: 'Nome do médico',
            required: true,
          },
          {
            label: 'Especialidade',
            name: 'speciality',
            type: 'text',
            placeholder: 'Especialidade',
          },
          {
            label: 'Telefone do médico',
            type: 'tel',
            placeholder: '+99 (99) 99999-9999',
            name: 'telephone',
          },
        ],
      },
      {
        route: 'hospital',
        label: 'Hospitais',
        model: 'hospitals',
        title: 'Hospitais',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'Nome',
            name: 'name',
            type: 'text',
            placeholder: 'Nome do hospital',
            required: true,
          },
          {
            label: 'Endereço',
            name: 'address',
            type: 'text',
            placeholder: 'Endereço',
          },
          {
            label: 'Telefone do hospital',
            type: 'tel',
            placeholder: '+99 (99) 99999-9999',
            name: 'telephone',
          },
        ],
      },
      {
        route: 'healthPlan',
        label: 'Planos de saúde',
        model: 'healthPlans',
        title: 'Planos de saúde',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'Nome',
            name: 'name',
            type: 'text',
            placeholder: 'Nome do plano',
            required: true,
          },
          {
            label: 'Número de identificação',
            name: 'number',
            type: 'number',
            placeholder: 'Número',
            required: true,
          },
        ],
      },
    ],
  },
  {
    route: 'contact',
    label: 'Contatos',
    model: 'contacts',
    title: 'Contatos de emergência',
    description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
    fields: [
      {
        label: 'Nome',
        name: 'name',
        type: 'text',
        placeholder: 'Nome do seu contato',
        required: true,
      },
      {
        label: 'Essa pessoa é meu/minha',
        type: 'select',
        name: 'relationship',
        placeholder: 'Grau de parentesco',
        options: [
          { label: 'Pai/Mãe', value: 'parent' },
          { label: 'Avô/Avó', value: 'grandparent' },
          { label: 'Bisavô/Bisavó', value: 'greatparent' },
          { label: 'Irmão/Irmã', value: 'sibling' },
          { label: 'Primo/Prima', value: 'nibling' },
          { label: 'Filho/Filha', value: 'child' },
          { label: 'Amigo/Amiga', value: 'friend' },
          { label: 'Namorado/Namorada', value: 'partner' },
          { label: 'Noivo/Noiva', value: 'fiance' },
          { label: 'Marido/Esposa', value: 'spouse' },
          { label: 'Tio/Tia', value: 'uncle_aunt' },
          { label: 'Sogro/Sogra', value: 'parent_in_law' },
          { label: 'Cunhado/Cunhada', value: 'sibling_in_law' },
          { label: 'Genro/Nora', value: 'child_in_law' },
          { label: 'Padrasto/Madrasta', value: 'step_parent' },
          { label: 'Enteado/Enteada', value: 'step_child' },
          { label: 'Padrinho/Madrinha', value: 'godparent' },
          { label: 'Afilhado/Afilhada', value: 'godchild' },
          { label: 'Outro', value: 'other' },
        ],
      },
      {
        label: 'Telefone do contato',
        type: 'tel',
        placeholder: '+99 (99) 99999-9999',
        name: 'telephone',
      },
    ],
  },
  {
    route: 'account',
    label: 'Conta',
    title: 'Sua conta',
    description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
    models: [
      {
        route: 'user',
        label: 'Usuário',
        model: 'user',
        type: 'object',
        title: 'Usuário',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'Nome Completo',
            name: 'fullName',
            type: 'text',
            placeholder: 'Nome Completo',
          },
          // {
          //   label: 'Email',
          //   name: 'email',
          //   type: 'text',
          //   placeholder: 'Email',
          // },
          {
            label: 'Cpf',
            type: 'text',
            inputMode: 'numeric',
            placeholder: 'Cpf',
            name: 'cpf',
          },
          {
            label: 'Rg',
            type: 'text',
            inputMode: 'numeric',
            placeholder: 'Rg',
            name: 'rg',
          },
          {
            label: 'Telefone',
            name: 'telephone',
            type: 'tel',
            placeholder: '+99 (99) 99999-9999',
          },
          {
            label: 'Notificações de marketing',
            name: 'notification',
            type: 'checkbox',
            placeholder: 'Notificação',
          },
        ],
      },
      {
        route: 'address',
        label: 'Endereço',
        model: 'address',
        title: 'Endereço',
        type: 'object',
        description: 'Ative ou desative as informações que você quer mostrar no CARD IASOS',
        fields: [
          {
            label: 'ismain',
            name: 'isMain',
            type: 'hidden',
            value: true,
            placeholder: 'ismain',
          },
          {
            label: 'Logradouro',
            name: 'street',
            type: 'text',
            placeholder: 'Logradouro',
          },
          {
            label: 'Número',
            name: 'number',
            type: 'number',
            placeholder: 'Número',
          },
          {
            label: 'Bairro',
            name: 'district',
            type: 'text',
            placeholder: 'Bairro',
          },
          {
            label: 'Complemento',
            name: 'complement',
            type: 'text',
            placeholder: 'Complemento',
          },
          {
            label: 'Cep',
            name: 'postalCode',
            type: 'text',
            inputMode: 'numeric',
            placeholder: 'Cep',
          },
          {
            label: 'Cidade',
            name: 'city',
            type: 'text',
            placeholder: 'Cidade',
          },
          {
            label: 'Estado',
            name: 'state',
            type: 'text',
            placeholder: 'Estado',
          },
        ],
      },
    ],
  },
];

export type Model = {
  route: string;
  label: string;
  title: string;
  type?: 'object' | 'array';
  description: string;
  // icon: ReactNode;
  models?: Model[];
  model?: string;
  fields?: Field[];
};

export interface Field {
  name: string;
  label: string;
  value?: any;
  //outer: se for true, o field não faz parte da tabela original e não pode ser renderizada ao editar ou adicionar dados da tabela
  outer?: boolean;
  type:
    | 'select'
    | 'radio'
    | 'checkbox'
    | 'text'
    | 'password'
    | 'textArea'
    | 'number'
    | 'file'
    | 'date'
    | 'range'
    | 'hidden'
    | 'tel';
  options?: {
    value: string;
    label: string;
  }[];
  required?: boolean;
  placeholder?: string;
  radios?: {
    checked?: boolean;
    value: string | number | undefined;
    left?: boolean;
    label: string;
    color?: 'black' | 'white';
  }[];
  fields?: Field[];
  min?: number;
  max?: number;
  step?: number;
  error?: string;
  helper?: string;
  inputMode?: 'numeric' | 'decimal' | 'text';
}

type Models = Model[];
