import { Box, Grid, Row, Title } from 'uisos';

export function NotFound() {
  return (
    <Grid as="main" gap={0}>
      <Row full style={{ height: '100vh' }} justify="center" align="center">
        <Box direction="column">
          <Title level={2} color="blue">
            404
          </Title>
          <Title level={4} color="black">
            Not Found
          </Title>
        </Box>
      </Row>
    </Grid>
  );
}
