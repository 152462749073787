import { useEffect } from 'react';
import { Grid } from 'uisos';
import { Header } from '../components/Header';
import { Modals } from '../components/Modals';
import { ProfileModels } from '../components/ProfileModels';
import { ProfileModelsHero } from '../components/ProfileModelsHero';

export function ProfileSubmodel() {
  useEffect(() => {
    document.body.style.backgroundColor = '#EEF7F7';
    return () => {
      document.body.style.backgroundColor = 'white';
    };
  }, []);

  return (
    <Grid as="main" gap={0}>
      <Modals />
      <Header />
      <ProfileModelsHero />
      <ProfileModels />
    </Grid>
  );
}
