import moment from 'moment';
import { useContext } from 'react';
import { CgPill } from 'react-icons/cg';
import { FaCheckCircle, FaPen, FaTimesCircle, FaTrash } from 'react-icons/fa';
import { Box, Card, Checkbox, Text } from 'uisos';
import { CustomerModel } from '../../@types/api';
import { ModalContext } from '../../context/ModalContext';
import { ProfileContext } from '../../context/ProfileContext';
import useMediaQuery from '../../hooks/useMediaQuery';

interface MedicationCardProps {
  data: {};
}

export function MedicationCard({ data }: MedicationCardProps) {
  const { openModal } = useContext(ModalContext);
  const { setId, remove, setCardVisibility } = useContext(ProfileContext);
  const {
    name,
    card_visibility,
    id,
    brand,
    cause,
    dose,
    observations,
    startdate,
    substance,
    enddate,
  } = data as unknown as CustomerModel['medications'][0];
  const today = moment().utc().startOf('day').toISOString();
  const sdate = startdate && moment(startdate).utc().startOf('day').toISOString();
  const edate = enddate && moment(enddate).utc().startOf('day').toISOString();
  const isMobile = useMediaQuery(768);

  return (
    <Card variant="default" style={{ width: '100%' }}>
      <Card.Main separator padding={15}>
        <Box align="center" height={57} justify="center" margin={[0, 16, 0, 0]} width="15%">
          <CgPill size={isMobile ? 32 : 52} />
        </Box>
        <Box
          align="flex-start"
          direction="column"
          height={104}
          justify={cause ? 'space-evenly' : 'center'}
          width="60%"
        >
          {cause && <Text>{cause}</Text>}
          <Text size="s" weight="bold">
            {name}
          </Text>
        </Box>
        <Box align="center" justify="space-around" height={57} width="25%">
          <FaPen
            size={isMobile ? 18 : 24}
            aria-hidden="true"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openModal('update'), setId(id);
            }}
          />
          <FaTrash
            aria-hidden="true"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openModal('remove'), setId(id);
            }}
            size={isMobile ? 18 : 24}
          />
        </Box>
      </Card.Main>
      {(dose || sdate || edate) && (
        <Card.Section separator>
          <Box justify="flex-start" width="100%">
            <Text
              size="xs"
              style={{
                marginRight: '1em',
              }}
              weight="bold"
            >
              Dose:
            </Text>
            <Text size="xs">{dose}</Text>
          </Box>
          <Box justify="space-between" width={100}>
            {today >= sdate && (!edate || today <= edate) ? (
              <>
                <FaCheckCircle color="#4CCE41" size={26} />
                <Text weight="bold">Em uso</Text>
              </>
            ) : (
              <>
                <FaTimesCircle color="red" size={26} />
                <Text weight="bold">Finalizado</Text>
              </>
            )}
          </Box>
        </Card.Section>
      )}
      {brand && (
        <Card.Section separator>
          <Box justify="flex-start" width="100%">
            <Text
              size="xs"
              style={{
                marginRight: '1em',
              }}
              weight="bold"
            >
              Marca:
            </Text>
            <Text size="xs">{brand}</Text>
          </Box>
        </Card.Section>
      )}
      {(startdate || enddate) && (
        <Card.Section separator>
          <Box justify="flex-start" width="100%">
            <Text
              size="xs"
              style={{
                marginRight: '1em',
              }}
              weight="bold"
            >
              Início:
            </Text>
            <Text size="xs" style={{ marginRight: 16 }}>
              {startdate ? moment(startdate).format('DD/MM/YYYY') : 'Não informado'}
            </Text>
            <Text
              size="xs"
              style={{
                marginRight: '1em',
              }}
              weight="bold"
            >
              Término:
            </Text>
            <Text size="xs">
              {enddate ? moment(enddate).format('DD/MM/YYYY') : 'Não informado'}
            </Text>
          </Box>
        </Card.Section>
      )}
      {observations && (
        <Card.Section separator>
          <Box justify="flex-start" width="100%">
            <Text
              size="xs"
              style={{
                marginRight: '1em',
              }}
              weight="bold"
            >
              Observação:
            </Text>
            <Text size="xs">{observations}</Text>
          </Box>
        </Card.Section>
      )}
      <Card.Footer>
        <Checkbox checked={card_visibility} name="visibility" onClick={() => setCardVisibility(id)}>
          Visibilidade no cartão IASOS
        </Checkbox>
      </Card.Footer>
    </Card>
  );
}
