export default (fulName: string) => {
  const arr = fulName.split(' ');
  if (arr.length === 1) {
    return `${arr[0]}`;
  }
  if (arr[1].length <= 3 && (arr[1][0] === 'd' || arr[1][0] === 'D' || arr[1].includes('.'))) {
    return `${arr[0]} ${arr[1]} ${arr[2]}`;
  }
  return `${arr[0]} ${arr[1]}`;
};
