import { useContext } from 'react';
import { FaHospitalAlt, FaPen, FaTrash } from 'react-icons/fa';
import { Box, Card, Checkbox, Text, Title } from 'uisos';
import { CustomerModel } from '../../@types/api';
import { ModalContext } from '../../context/ModalContext';
import { ProfileContext } from '../../context/ProfileContext';
import useMediaQuery from '../../hooks/useMediaQuery';

interface HospitalCardProps {
  data: {};
}

export function HospitalCard({ data }: HospitalCardProps) {
  const { openModal } = useContext(ModalContext);
  const { setId, remove, setCardVisibility } = useContext(ProfileContext);
  const { name, card_visibility, id, address, telephone } =
    data as unknown as CustomerModel['hospitals'][0];
  const isMobile = useMediaQuery(768);

  return (
    <Card variant="middle" style={{ width: '100%' }}>
      <Card.Main separator padding={15}>
        <Box align="center" height={57} justify="center" margin={[0, 16, 0, 0]} width="15%">
          <FaHospitalAlt size={isMobile ? 32 : 52} />
        </Box>
        <Box align="flex-start" direction="column" height={104} justify="space-evenly" width="60%">
          <Text as="small" size="xs" style={{ color: 'grey' }}>
            {address}
          </Text>
          <Title level={5} weight="bold">
            {name}
          </Title>
          <Text size="s">{telephone}</Text>
        </Box>
        <Box align="center" justify="space-around" height={57} width="25%">
          <FaPen
            size={isMobile ? 18 : 24}
            aria-hidden="true"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openModal('update'), setId(id);
            }}
          />
          <FaTrash
            aria-hidden="true"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openModal('remove'), setId(id);
            }}
            size={isMobile ? 18 : 24}
          />
        </Box>
      </Card.Main>
      <Card.Footer padding={[8, 18]}>
        <Checkbox checked={card_visibility} name="visibility" onClick={() => setCardVisibility(id)}>
          Visibilidade no cartão IASOS
        </Checkbox>
      </Card.Footer>
    </Card>
  );
}
