import { useContext } from 'react';
import { Box, Row } from 'uisos';
import { CardContext } from '../../context/CardContext';
import { CardHero } from './CardHero';
import { CardSection } from './CardSection';

interface CardDataProps {}

export function CardData({}: CardDataProps) {
  const { sections } = useContext(CardContext);
  return (
    <>
      <Row full>
        <Box padding={[24, 24, 0]} align="center" height={'fit-content'} width={'100%'}>
          <CardHero />
        </Box>
      </Row>
      {sections.map(({ title, subtitle, model, item, color }) => (
        <CardSection
          item={item}
          model={model}
          title={title}
          subtitle={subtitle}
          color={color}
          key={title}
        />
      ))}
    </>
  );
}
