import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Image, Row, Title } from 'uisos';
import useMediaQuery from '../hooks/useMediaQuery';

export function Home() {
  const navigate = useNavigate();
  const breakpoint = useMediaQuery(1024);

  return (
    <Grid as="main" gap={0}>
      <Row full flex justify="center" align="center" style={{ height: breakpoint ? '35vh' : 253 }}>
        <Box height={'100%'} direction="column" justify="center" align="center">
          <Title level={5} weight="bold" style={{ marginBottom: 16 }}>
            Bem vindo à
          </Title>
          <Image
            alt="Logo tipo da iasos em vermelho"
            src="https://images.prismic.io/iasos-test/5872117a-17e0-49a2-bb74-a90cf674533b_iasos_logo.png?auto=compress,format"
            width={breakpoint ? '45%' : '30%'}
          ></Image>
        </Box>
      </Row>
      <Row
        full
        flex
        justify="center"
        align="center"
        style={{ height: breakpoint ? '25vh' : 218, zIndex: -1 }}
      >
        <Box height={'100%'} direction="column" justify="center" align="center" width={'100%'}>
          <Image
            loading="lazy"
            alt="Menina mostrando o fundo celular com o adesivo iasos"
            src="https://images.prismic.io/iasos-test/e29303d8-411a-4213-bee5-9e81a0a12516_image_home.png?auto=compress,format"
          />
        </Box>
      </Row>
      <Row full flex justify="center" align="center" style={{ height: breakpoint ? '40vh' : 341 }}>
        <Box height={'100%'} direction="column" justify="center" align="center" width={271}>
          <Button
            size="large"
            color="secondary"
            light
            full
            style={{ marginBottom: 16 }}
            onClick={() => navigate('/login')}
          >
            Entrar
          </Button>
          <Button size="large" color="secondary" full onClick={() => navigate('/register')}>
            Criar conta
          </Button>
        </Box>
      </Row>
    </Grid>
  );
}
