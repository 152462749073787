import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { ForgotPasswordData } from '../components/ForgotPasswordForm';
import { LoginData } from '../components/LoginForm';
import { RegisterData } from '../components/RegisterForm';
import { ResetPasswordData } from '../components/ResetPasswordForm';
import { ValidationData } from '../components/ValidationForm';
import { deleteCookie, getCookie, setCookie } from '../helpers/cookies';
import api from '../services/api';
import { useTranslation } from 'react-i18next';

export default () => {
  const [sendedEmail, setSendedEmail] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [redirect, setRedirect] = useState('');
  const [resending, setResending] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const token = getCookie('auth_token');
    if (token) {
      (async () => await authenticate(token))().then(() => setLoading(false));
    } else {
      setAuthenticated(false);
      setLoading(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setError({});
  }, [location]);

  const authenticate = async (token: string) => {
    try {
      api.defaults.headers['authorization'] = `Bearer ${token}` as any;
      api.defaults.headers['activate'] = getCookie('activation_token');

      /*
       * Verifica se o usuário está validado por cookie, ao espirar valida no back-end novamente
       * atualmente é impossível acessar a plataforma sem validar o email
       */
      if (location.pathname.includes('profile') && getCookie('user_validated')) {
        setAuthenticated(true);
        return;
      }

      if (location.pathname.includes('tutorial')) {
        return;
      }

      /*
       * Caso não haja cookie de validação, busca no back-end
       */
      const { data: customer } = await api.get('/customer');

      if (!customer.user.emailValidated) {
        /*
         * Caso não esteja validado encaminha para validação
         */
        if (getCookie('activation_token')) await api.patch('/link/customer');

        setAuthenticated(true);
        navigate('/validation');
      } else if (!location.pathname.includes('profile') && getCookie('activation_token')) {
        /*
         * Situação de pós login para quando o usuário ainda está registrando os primeiros dados
         */
        setCookie('user_validated', 'true', 1);
        await api.patch('/link/customer');
        setAuthenticated(true);

        if (customer.finished) {
          navigate('/register/finished');
        } else {
          navigate('/register/step');
        }
      } else {
        /*
         * Quando o usuário acessa a plataforma e já está logado, pós login, ou alteração de rota
         * Se já está logado encaminha para a sua página, se não o mantêm de onde parou
         */
        setCookie('user_validated', 'true', 1);
        setAuthenticated(true);
        navigate(location.pathname.includes('/user') ? location.pathname : '/user/profile');
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const handleError = (error: unknown) => {
    if (error instanceof AxiosError) {
      const data: AxiosResponse['data'] = error.response?.data;

      if (data?.validation) {
        setError({
          key: data.validation.body.keys[0],
          message: data.validation.body.message,
        });
      } else {
        setError(data);
      }
    } else if (error instanceof Error) {
      setError(error);
    }
    setLoading(false);
  };

  const handleLogin = async (login: LoginData) => {
    try {
      setLoading(true);

      const { data } = await api.post('/login', login);

      setCookie('auth_token', data, 1);

      authenticate(data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleLogout = () => {
    setLoading(true);

    deleteCookie('auth_token');
    setAuthenticated(false);
    navigate('/login/profile');

    setLoading(false);
  };

  const handleRegister = async (register: RegisterData) => {
    try {
      setLoading(true);

      const { confirmPassword, terms, privacy, ...userData } = register;

      if (confirmPassword === userData.password && terms && privacy) {
        const { data } = await api.post('/user', {
          ...userData,
        });

        setCookie('auth_token', data, 1);

        authenticate(data);
      } else if (!terms || !privacy) {
        throw Error(`${t('You need to accept the terms and privacy policy to continue')}`);
      } else {
        throw Error(`${t('The password must be equal in the two fields')}`);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleForgotPassword = async (data: ForgotPasswordData) => {
    try {
      setLoading(true);

      await api.post(`/user${location.pathname}${location.search}`, data);
      setSendedEmail(data.email);
      setError({});
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const handleReset = async (resetPassword: ResetPasswordData, e: any) => {
    try {
      setLoading(true);

      const { confirmPassword, password } = resetPassword;

      if (confirmPassword === password) {
        const { data } = await api.post(`/user${location.pathname}?${searchParams}`, {
          password,
        });

        setRedirect(data.redirect);
      } else {
        throw Error(`${t('The password must be equal in the two fields')}`);
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const handleValidation = async (validation: ValidationData) => {
    try {
      const isActivating = getCookie('activation_token');
      setLoading(true);

      const { code } = validation;

      await api.post(`/user/confirm`, {
        code,
      });

      const { data: customer } = await api.get('/customer');

      setEmailValidated(true);
      setCookie('user_validated', 'true', 1);

      if (!customer.finished) {
        navigate('/register/step');
      } else if (customer.finished && isActivating) {
        navigate('/register/finished');
      } else {
        navigate('/login/profile');
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const resendCode = async () => {
    setResending(true);
    await api.post(`/user/verify`, { resend: true });
    setResending(false);
  };

  return {
    handleLogin,
    handleRegister,
    handleLogout,
    handleForgotPassword,
    handleReset,
    handleValidation,
    resendCode,
    setError,
    authenticated,
    loading,
    error,
    sendedEmail,
    redirect,
    resending,
    emailValidated,
  };
};
