import { useContext } from 'react';
import { Box, Card, Text, Title } from 'uisos';
import { CustomerModel } from '../../@types/api';
import { ProfileContext } from '../../context/ProfileContext';
import { InfoIcon } from '../InfoIcon';
import { useTranslation } from 'react-i18next';
import { UserIcon } from '../UserIcon';
import { AddressIcon } from '../AddressIcon';
import useMediaQuery from '../../hooks/useMediaQuery';
import getEtCetera from '../../helpers/getEtCetera';

interface UserAddressCardProps {
  data: {};
  type: 'user' | 'address';
}

export function UserAddressCard({ data, type }: UserAddressCardProps) {
  const { t } = useTranslation();
  const { customer } = useContext(ProfileContext);
  const dataList = Object.entries({ ...(data as CustomerModel['user']) });
  const isMobile = useMediaQuery(768);

  return (
    <Card style={{ width: '100%' }}>
      {dataList.map(([key, value]) => {
        return (
          <Card.Section key={key} separator>
            <Box align="center" height={57} width={isMobile ? '10%' : '5%'}>
              {type === 'user' ? (
                <UserIcon keyName={key} size={isMobile ? 22 : 37} />
              ) : (
                <AddressIcon keyName={key} size={isMobile ? 22 : 37} />
              )}
            </Box>
            <Box
              align="flex-start"
              direction="column"
              height={64}
              justify="space-evenly"
              width={isMobile ? '80%' : '100%'}
            >
              {key === 'email' ? (
                <Box width={'100%'} justify="space-between">
                  <Title level={6}>{t(key)}</Title>
                  <Text weight="bold">
                    {customer && customer?.user.emailValidated ? 'Validado' : 'Inválidado'}
                  </Text>
                </Box>
              ) : (
                <Title level={6}>{t(key)}</Title>
              )}
              <Text size="xs">
                {value || value?.toString()
                  ? getEtCetera(t(value?.toString()), 40)
                  : 'Não informado'}
              </Text>
            </Box>
          </Card.Section>
        );
      })}
    </Card>
  );
}
