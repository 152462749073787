import { useContext, useEffect, useState } from 'react';
import { Box, Title, Text, Section, Row, Button } from 'uisos';
import { ImPlus } from 'react-icons/im';
import { IoIosArrowBack } from 'react-icons/io';
import { ProfileContext } from '../../context/ProfileContext';
import { useNavigate } from 'react-router-dom';
import { ModalContext } from '../../context/ModalContext';
import useBack from '../../hooks/useBack';
import getDataFromCustomer from '../../helpers/getDataFromCustomer';
import { CustomerModel } from '../../@types/api';
import { FaPen } from 'react-icons/fa';
import useMediaQuery from '../../hooks/useMediaQuery';

export function ProfileModelsHero() {
  const [data, setData] = useState<null | any | keyof CustomerModel>(null);
  const { model, loading, customer, setId } = useContext(ProfileContext);
  const { openModal } = useContext(ModalContext);
  const goBack = useBack();
  const isMobile = useMediaQuery(768);

  useEffect(() => {
    if (customer && model?.model) {
      const modelData = getDataFromCustomer(customer, model.model as keyof CustomerModel);
      setData(modelData);
    }
  }, [customer, model]);

  return model ? (
    <Section color="light" style={{ padding: '32px 0 0 0', marginLeft: isMobile ? 0 : 88 }}>
      <Row>
        <Box align="center" margin={[0, 0, 8, 0]} height="100%" width="100%" justify="flex-start">
          <IoIosArrowBack
            aria-hidden={true}
            onClick={() => goBack()}
            color="#767676"
            size={24}
            style={{ marginRight: 8, cursor: 'pointer' }}
          />
          <Title level={5}>{model.label}</Title>
        </Box>
        <Box
          direction="column"
          align="flex-start"
          margin={[0, 0, 0, 32]}
          height="100%"
          width="100%"
          justify="space-between"
        >
          <Text size="s">{model.description}</Text>
        </Box>
      </Row>
      {model.fields && (
        <Row align="flex-end" justify="flex-end">
          <Box margin={[16, 0, 0, 0]} width={model.model === 'address' && data ? 252 : 126}>
            {model.type && model.type === 'object' && data ? (
              <>
                <Button size="small" full onClick={() => openModal('update')}>
                  Editar
                  <Box>
                    <FaPen color="white" />
                  </Box>
                </Button>
                {model.model === 'address' && (
                  <Button
                    size="small"
                    style={{ marginLeft: 16 }}
                    full
                    onClick={() => {
                      openModal('remove'), setId(data.id);
                    }}
                  >
                    Apagar
                    <Box>
                      <FaPen color="white" />
                    </Box>
                  </Button>
                )}
              </>
            ) : (
              <Button size="small" full onClick={() => openModal('add')} loading={loading}>
                Adicionar
                <Box>
                  <ImPlus color="white" />
                </Box>
              </Button>
            )}
          </Box>
        </Row>
      )}
    </Section>
  ) : null;
}
