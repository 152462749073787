import { Box, Col, Image, Row, Section, Text, Title } from 'uisos';
import { ProfileContext } from '../../context/ProfileContext';
import { useContext, useState } from 'react';
import { Avatar } from './styles';
import { Menu } from '../Menu';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ReactLoading from 'react-loading';
// import { Desktop as DesktopMenu } from '../Menu/desktop';

export function Desktop() {
  const { customer, loading } = useContext(ProfileContext);
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {customer && (
        <>
          <Menu open={menu} setOpen={setMenu} />
          <Section
            as="header"
            color="light"
            style={{ boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.15)', zIndex: 1, marginLeft: 88 }}
          >
            <Row style={{ height: 83 }}>
              <Col span={6} align="center" justify="flex-start">
                <Box>
                  <Image
                    onClick={() => navigate('/user/profile')}
                    src="https://images.prismic.io/iasos-test/8824be24-4881-471b-83e1-469c8ee58f11_IASOS_2+2.png?auto=compress,format"
                    alt=""
                    height="auto"
                    link
                    width={120}
                  />
                </Box>
              </Col>
              <Col span={6} align="center" justify="flex-end">
                <Box
                  width="fit-content"
                  align="flex-start"
                  justify="space-between"
                  onClick={() => navigate('/user/profile/account/user')}
                  style={{ cursor: 'pointer' }}
                >
                  {loading ? (
                    <ReactLoading type="spin" color="#269898" width={32} height={'100%'} />
                  ) : (
                    <Avatar
                      src={`${process.env.REACT_APP_API_BASE_URL}/files/${
                        customer?.avatar || process.env.DEFAULT_AVATAR
                      }`}
                      alt="Avatar do usuário"
                      height={32}
                      width={32}
                    />
                  )}
                  <Box direction="column" margin={[0, 0, 0, 8]} align="flex-start">
                    <Text size="xs">Olá, {customer.full_name.split(' ')[0]}</Text>
                    <Text weight="bold" size="xs">
                      {t(customer.user.role)}
                    </Text>
                  </Box>
                </Box>
              </Col>
            </Row>
          </Section>
        </>
      )}
    </>
  );
}
