import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { Modals } from './components/Modals';
import MainRoutes from './routes/MainRoutes';
import GlobalStyles from './styles/global';
import CookieAd from './components/CookieAd';

export default function App() {
  const { pathname } = useLocation();

  const getColorByPath = () => {
    if (pathname.includes('/user/profile')) {
      return '#EEF7F7';
    }
    if (pathname.includes('/register/step')) {
      return '#e6f0f0';
    }
    return '#fff';
  };

  return (
    <div className="App">
      <Helmet>
        <meta name="theme-color" content={getColorByPath()} media="(prefers-color-scheme: light)" />
        <meta name="theme-color" content={getColorByPath()} media="(prefers-color-scheme: dark)" />
        <meta property="og:title" content="Iasos" />
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="IASOS - Todas as suas informações emergenciais em um só lugar, segurança para quem você mais ama."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://images.prismic.io/iasos-test/06b85ac4-1c90-4d3c-8433-934f026e3c32_svgg+%281%29.png?auto=compress,format"
        />
      </Helmet>
      <CookieAd />
      <GlobalStyles />
      <MainRoutes />
    </div>
  );
}
