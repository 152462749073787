import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import getModels, { Model } from '../data/getModels';
import toCamelCase from '../helpers/toCamelCase';
import api from '../services/api';
import fetcher from '../services/fetcher';

export default () => {
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState<Model[]>([]);
  const [id, setId] = useState<string>('');
  const [error, setError] = useState({ key: '', message: '' });
  const [model, setModel] = useState<Model>({} as Model);
  const params = useParams();
  const { pathname } = useLocation();

  const { data: customer, mutate: mutateCustomer } = useSWR(mounted ? '/customer' : null, fetcher);
  const { data: card, mutate: mutateCard } = useSWR(
    customer && customer.healthData && pathname.includes('info') && mounted ? '/card' : null,
    fetcher,
  );

  useEffect(() => {
    setMounted(true);
    setLoading(true);

    if (params.model) {
      const model = getModels().find((model) => model.route === params.model);
      if (model?.models) {
        setModels(model.models);
        setModel(model);
      } else if (model && !model.models) {
        setModels([]);
        setModel(model);
      } else {
        setModels([]);
      }

      if (params.submodel && model && model.models) {
        const submodel = model.models.find((model) => model.route === params.submodel);
        if (submodel?.models) {
          setModels(submodel.models);
        } else if (submodel) {
          setModels([]);
          setModel(submodel);
        } else {
          setModels([]);
        }
      }
    } else {
      setModels(getModels());
    }
    setLoading(false);
  }, [params]);

  useEffect(() => {
    if (customer && models.length) {
      setLoading(false);
    }
  }, [customer, models]);

  const add = async (data: any) => {
    setLoading(true);
    try {
      if (model.model) {
        await api.post(`/${model.route}`, data);

        if (model.type === 'object') {
          await mutateCustomer({
            ...customer,
            [model.model]: data,
          });
        } else {
          const newData = { ...data, card_visibility: true };

          await mutateCustomer({
            ...customer,
            [model.model]: [...customer[model.model], newData],
          });
        }
      }
      setError({ key: '', message: '' });
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const remove = async () => {
    setLoading(true);
    try {
      if (model.model && id) {
        await api.delete(`/${model.route}/${id}`);

        if (model.type === 'array') {
          const index = customer[model.model].findIndex((e: any) => e.id === id);
          await mutateCustomer({
            ...customer,
            [model.model]: [
              ...customer[model.model].slice(0, index),
              ...customer[model.model].slice(index + 1),
            ],
          });
        } else {
          await mutateCustomer({
            ...customer,
            [model.model]: null,
          });
        }
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const edit = async (data: any) => {
    setLoading(true);
    try {
      if (model.model && (id || model.type === 'object')) {
        await api.put(`/${model.route}/${id || ''}`, data);

        if (id) {
          const index = customer[model.model].findIndex((e: any) => e.id === id);
          const edited = customer[model.model].find((e: any) => e.id === id);

          await mutateCustomer({
            ...customer,
            [model.model]: [
              ...customer[model.model].slice(0, index),
              { ...edited, ...data },
              ...customer[model.model].slice(index + 1),
            ],
          });
        } else {
          await mutateCustomer({
            ...customer,
            [model.model]: {
              ...data,
            },
          });
        }
      }
      setError({ key: '', message: '' });
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const setCardVisibility = async (id: string) => {
    try {
      if (model.model) {
        const index = customer[model.model].findIndex((e: any) => e.id === id);
        const edited = customer[model.model].find((e: any) => e.id === id);

        await api.put(`/${model.route}/${id}`, { cardVisibility: !edited.card_visibility });

        await mutateCustomer({
          ...customer,
          [model.model]: [
            ...customer[model.model].slice(0, index),
            { ...edited, card_visibility: !edited.card_visibility },
            ...customer[model.model].slice(index + 1),
          ],
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const setCardVisibilityOnHealthData = async (key: string) => {
    try {
      await api.put(`/card`, { [toCamelCase(key)]: !card[key] });

      await mutateCard({
        [key]: !card[key],
        ...card,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const updateAvatar = async (file: any) => {
    setLoading(true);
    try {
      if (file[0].size > 5242880) {
        setError({
          message: 'File size not accepted, please choose an image with 5MB or less',
          key: 'avatar',
        });
        setLoading(false);
      }

      const formData = new FormData();

      formData.append('avatar', file[0]);
      const avatar = await api.post('/avatar', formData);

      await mutateCustomer({
        ...customer,
        avatar,
      });
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const updateNotification = async () => {
    try {
      await api.put('/customer/notification');

      await mutateCustomer({
        ...customer,
        notification: !customer.notification,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error: unknown) => {
    if (error instanceof AxiosError) {
      const data: AxiosResponse['data'] = error.response?.data;

      if (data.validation?.body) {
        setError({ message: data.validation.body.message, key: data.validation.body.keys[0] });
      } else if (data.validation?.params) {
        setError({ message: data.validation.params.message, key: data.validation.params.keys[0] });
      } else {
        setError(data);
      }
    } else if (error instanceof Error) {
      setError(error as unknown as { key: string; message: string });
    }
    setLoading(false);
  };

  return {
    customer,
    loading,
    models,
    model,
    error,
    id,
    card,
    edit,
    add,
    remove,
    setId,
    setError,
    setCardVisibility,
    setCardVisibilityOnHealthData,
    updateAvatar,
    updateNotification,
  };
};
