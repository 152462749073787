import { useLocation, useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  if (pathname.includes('/user/profile') && pathname !== '/user/profile') {
    return () => navigate(pathname.split('/').slice(0, -1).join('/'));
  }
  return () => navigate(-1);
};
