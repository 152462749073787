import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { Card, Text } from 'uisos';
import { CustomerModel } from '../../@types/api';
import { ModalContext } from '../../context/ModalContext';
import { ProfileContext } from '../../context/ProfileContext';
import { StepContext } from '../../context/StepContext';
import sortByCreateDate from '../../helpers/sortByCreateDate';

interface StepDataListProps {
  model?: CustomerModel['allergies'] | CustomerModel['contacts'] | CustomerModel['conditions'];
}

export function StepDataList({ model }: StepDataListProps) {
  const { removeOnStep, steps, step } = useContext(StepContext);
  const { t } = useTranslation();
  return (
    <>
      {model && steps && Array.isArray(model) && model.length
        ? sortByCreateDate(model).map((data, index) => (
            <Card
              key={data.telephone ? data.telephone : data?.name}
              // key={data?.name}
              align="flex-start"
              style={{ flexDirection: 'row', boxShadow: '0px 0px 3px rgba(0,0,0,0.1)' }}
              justify="space-between"
              padding={[8, 16]}
              margin={[0, 0, index === model.length - 1 ? 16 : 0, 0]}
              variant="middle"
            >
              <Text weight="bold">
                {data?.name} -{' '}
                <Text as="span">{data?.type ? t(data.type) : t(data.relationship)}</Text>
              </Text>
              <Text
                underline
                onClick={() => removeOnStep(steps[step].route, steps[step].model, data.id)}
              >
                Apagar
              </Text>
            </Card>
          ))
        : null}
    </>
  );
}
