import { useContext, useEffect } from 'react';
import { Grid } from 'uisos';
import { Header } from '../components/Header';
import { ProfileHero } from '../components/ProfileHero';
import { ProfileModels } from '../components/ProfileModels';
import { ProfileContext } from '../context/ProfileContext';
import { Loading } from './Loading';

export function Profile() {
  const { customer } = useContext(ProfileContext);

  useEffect(() => {
    document.body.style.backgroundColor = '#EEF7F7';
    return () => {
      document.body.style.backgroundColor = 'white';
    };
  }, []);

  return (
    <>
      {!customer ? (
        <Loading />
      ) : (
        <Grid as="main" gap={0}>
          <Header />
          <ProfileHero />
          <ProfileModels />
        </Grid>
      )}
    </>
  );
}
