import { Box, Button, Col, Grid, Input, Row, Title, Form, Text } from 'uisos';
import AppBox from '../components/AppBox';
import { FormPage } from '../components/FormPage';
import { LoginForm } from '../components/LoginForm';
import { Modals } from '../components/Modals';
import useMediaQuery from '../hooks/useMediaQuery';

export function Login() {
  return (
    <Grid as="main" gap={0} style={{ height: '100vh' }}>
      <Modals />
      <FormPage
        title={
          <Title level={4} color="blue">
            Acesse sua conta
          </Title>
        }
      >
        <LoginForm />
      </FormPage>
    </Grid>
  );
}
