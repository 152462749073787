import { Box, Col, Image, Row, Section } from 'uisos';
import { ProfileContext } from '../../context/ProfileContext';
import { useContext, useState } from 'react';
import { Loading } from '../../pages';
import { Avatar } from './styles';
import { Menu } from '../Menu';
import { IoMenu } from 'react-icons/io5';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Desktop } from './desktop';
import ReactLoading from 'react-loading';

export function Header() {
  const { customer, loading } = useContext(ProfileContext);
  const [menu, setMenu] = useState(false);
  const isMobile = useMediaQuery(768);
  return (
    <>
      {isMobile ? (
        <>
          <Menu open={menu} setOpen={setMenu} />
          <Section
            as="header"
            color="light"
            style={{ boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.15)', zIndex: 1 }}
          >
            <Row style={{ height: 104 }}>
              <Col span={1} align="flex-end">
                <Box margin={[0, 0, 16, 0]}>
                  <IoMenu
                    size={24}
                    onClick={() => setMenu(true)}
                    aria-hidden="true"
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              </Col>
              <Col span={10} align="flex-end">
                <Box margin={[0, 0, 18, 0]}>
                  <Image
                    src="https://images.prismic.io/iasos-test/8824be24-4881-471b-83e1-469c8ee58f11_IASOS_2+2.png?auto=compress,format"
                    alt=""
                    height="auto"
                    width={76}
                  />
                </Box>
              </Col>
              <Col span={1} align="flex-end">
                <Box width="fit-content" justify="space-between" margin={[0, 0, 16, 0]}>
                  {loading ? (
                    <ReactLoading type="spin" color="#269898" width={32} height={'100%'} />
                  ) : (
                    <Avatar
                      src={`${process.env.REACT_APP_API_BASE_URL}/files/${
                        customer?.avatar || process.env.DEFAULT_AVATAR
                      }`}
                      alt="Avatar do usuário"
                      height={32}
                      width={32}
                    />
                  )}
                </Box>
              </Col>
            </Row>
          </Section>
        </>
      ) : (
        <Desktop />
      )}
    </>
  );
}
