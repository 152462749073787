import styled from 'styled-components';

export const EditButton = styled.div`
  color: #2f9897;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 420px) {
    font-size: 14px;
  }

  div {
    margin-left: 12px;
    background-color: #f4f4f4;
    border: solid 1px #dfdfdf;
    border-radius: 100%;
    padding: 4px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
