import styled, { css } from 'styled-components';
import { Col, Image } from 'uisos';

interface AvatarProps {
  width: number | string;
  height: number | string;
  alt: string;
  src: string;
}

export const HeaderCol = styled(Col)`
  min-height: initial !important;
`;

export const Avatar = styled.div<AvatarProps>`
  ${({ width, height, src }) => css`
    width: ${typeof width === 'number' ? width + 'px' : width};
    height: ${typeof height === 'number' ? height + 'px' : height};
    background-image: url(${src});
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `}
`;
