import { createContext, ReactNode, useEffect, useState } from 'react';
import { QrCodeModel } from '../@types/api';
import getSections, { Section } from '../data/getSections';

interface CardProviderProps {
  children: ReactNode;
}

interface CardContextType {
  cardData: QrCodeModel;
  setCardData: (cardData: QrCodeModel) => void;
  sections: Section[];
}

const CardContext = createContext<CardContextType>({
  cardData: {} as QrCodeModel,
  setCardData: (cardData: QrCodeModel) => {},
  sections: [],
});

function CardProvider({ children }: CardProviderProps) {
  const [cardData, setCardData] = useState({} as QrCodeModel);
  const [sections, setSections] = useState([] as Section[]);

  useEffect(() => {
    setSections(getSections());
  }, []);

  return (
    <CardContext.Provider value={{ cardData, setCardData, sections }}>
      {children}
    </CardContext.Provider>
  );
}

export { CardContext, CardProvider };
