import {
  Box,
  Button,
  Card,
  Checkbox,
  Col,
  FileInput,
  Form,
  Input,
  Radio,
  RadioGroup,
  Row,
  Select,
  Text,
  TextArea,
  Title,
} from 'uisos';
import { useContext, useEffect } from 'react';
import { ProfileContext } from '../../context/ProfileContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';
import { ModalContext } from '../../context/ModalContext';
import toCamelCase from '../../helpers/toCamelCase';
import getDataFromCustomer from '../../helpers/getDataFromCustomer';
import { CustomerModel, Step } from '../../@types/api';
import moment from 'moment';
import mask from '../../helpers/masks';
import useMasks from '../../hooks/useMasks';

interface DataFormsProps {
  onSubmit: ((data: object) => void) | ((data: object, id: string) => void);
}

interface FormData {
  [key: string]: any;
}

export function DataForms({ onSubmit }: DataFormsProps) {
  const {
    model,
    error: { key = '', message = '' },
    loading,
    id,
    customer,
    setError,
  } = useContext(ProfileContext);

  const { closeModal, isOpen, type } = useContext(ModalContext);

  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    formState: { isSubmitSuccessful },
  } = useForm<FormData>();

  useMasks(watch, setValue, (model?.fields || []) as Step['fields']);

  const fileName = watch('avatar');
  const organDonorValue = watch('organDonor');
  const smokerValue = watch('smoker');

  useEffect(() => {
    if (
      isOpen &&
      (id || model?.type === 'object') &&
      customer &&
      model?.fields &&
      type === 'update' &&
      model.model
    ) {
      let data;
      const fieldsList: any = model.fields.map(({ name, fields }) => name);

      model.fields.map(({ fields }) => fields && fields.map(({ name }) => fieldsList.push(name)));

      if (model.type === 'object') {
        data = getDataFromCustomer(customer, model.model as keyof CustomerModel);
      } else {
        data = customer[model?.model as keyof typeof customer].find((data: any) => data.id === id);
      }

      Object.entries(data).map(([key, value]) => {
        const fixedKey = toCamelCase(key);
        let fixedValue = value;
        fixedValue = fixedKey.includes('Date')
          ? moment(fixedValue as string).format('YYYY-MM-DD')
          : fixedValue;

        if (
          fieldsList.includes(fixedKey) &&
          typeof fixedValue === 'string' &&
          fixedKey.includes('date')
        ) {
          setValue(fixedKey, fixedValue.split('T')[0]);
        } else if (fieldsList.includes(fixedKey) && fixedValue !== null) {
          if (typeof fixedValue === 'boolean' && fixedKey !== 'notification') {
            fixedValue = fixedValue.toString();
          }

          setValue(fixedKey, fixedValue);
        } else if (fixedValue === null) {
          setValue(fixedKey, '');
        }
      });
    }
  }, [isOpen, customer, id, model?.fields, setValue, model?.model, type]);

  useEffect(() => {
    if (isSubmitSuccessful && !message) {
      closeModal();
      setError({});
    }
  }, [isSubmitSuccessful, closeModal, setError, message]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit as SubmitHandler<FormData>)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100%',
        minWidth: '100%',
      }}
    >
      <Title level={5} style={{ marginBottom: 16 }}>
        {model?.label}
      </Title>
      <Box direction="column" width="100%">
        {model?.fields &&
          model.fields.map((field, index) => {
            if (field.outer && type !== 'update') {
              return null;
            } else if (field.type === 'select') {
              return (
                <Select
                  label={field.label}
                  placeholder={field.placeholder}
                  key={index}
                  size="large"
                  required={field.required}
                  {...register(field.name)}
                  name={field.name}
                  error={key === field.name ? message : ''}
                >
                  {field.options?.map(({ value, label }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              );
            } else if (field.type === 'checkbox') {
              return (
                <Box key={index} width="100%" justify="space-between">
                  <Checkbox required={field.required} {...register(field.name)}>
                    <Text size="s">{field.label}</Text>
                  </Checkbox>
                </Box>
              );
            } else if (field.type === 'radio') {
              return (
                <RadioGroup key={index} label={field.label}>
                  {field.radios?.map(({ label, ...radio }, index) => {
                    return (
                      <Radio
                        {...radio}
                        checked={
                          field.name === 'smoker'
                            ? smokerValue === radio.value
                            : field.name === 'organDonor'
                            ? organDonorValue === radio.value
                            : undefined
                        }
                        value={radio.value || ''}
                        required={field.required}
                        {...register(field.name)}
                        key={index}
                        size="large"
                        name={field.name}
                      >
                        {label}
                      </Radio>
                    );
                  })}
                </RadioGroup>
              );
            } else if (field.type === 'textArea') {
              return (
                <TextArea
                  error={key === field.name ? message : ''}
                  size="large"
                  key={index}
                  placeholder={field.placeholder}
                  {...register(field.name)}
                  required={field.required}
                >
                  {field.label}
                </TextArea>
              );
            } else if (field.type === 'file') {
              return (
                <FileInput
                  key={index}
                  {...register(field.name)}
                  file={fileName && fileName[0]}
                  label={field.label}
                />
              );
            } else if (field.type === 'date') {
              return (
                <Input
                  min="1900-01-01"
                  error={key === field.name ? message : ''}
                  type={field.type}
                  key={index}
                  size="large"
                  {...register(field.name)}
                  placeholder={field.placeholder}
                  helper={field.helper}
                  required={field.required}
                >
                  {field.label}
                </Input>
              );
            } else if (field.type === 'range') {
              return (
                <Box width="100%" key={index}>
                  {field.fields?.map((f, idx) => (
                    <Box width="48%" key={idx}>
                      <Input
                        error={key === f.name ? message : ''}
                        type={f.type}
                        size="large"
                        {...register(f.name)}
                        placeholder={f.placeholder}
                        helper={f.helper}
                        required={f.required}
                      >
                        {f.label}
                      </Input>
                    </Box>
                  ))}
                </Box>
              );
            } else if (field.type === 'hidden') {
              return (
                <input
                  type={field.type}
                  key={index}
                  value={field.value}
                  {...register(field.name)}
                />
              );
            } else {
              return (
                <Input
                  inputMode={field.inputMode && field.inputMode}
                  error={key === field.name ? message : ''}
                  type={field.type}
                  key={index}
                  size="large"
                  {...register(field.name)}
                  placeholder={field.placeholder}
                  helper={field.helper}
                  required={field.required}
                >
                  {field.label}
                </Input>
              );
            }
          })}
        {message && !key ? (
          <Row>
            <ErrorMessage>{message}</ErrorMessage>
          </Row>
        ) : null}
      </Box>
      <Row>
        <Col span={6} style={{ paddingRight: 16 }}>
          <Button
            type="button"
            full
            size="large"
            light
            color="secondary"
            style={{ marginTop: 32 }}
            onClick={async () => {
              reset();
              closeModal();
              setError({});
            }}
          >
            Cancelar
          </Button>
        </Col>
        <Col span={6} style={{ paddingLeft: 16 }}>
          <Button
            type="submit"
            full
            size="large"
            color="secondary"
            style={{ marginTop: 32 }}
            loading={loading}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
