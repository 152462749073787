import { Box, Button, Col, Grid, Input, Row, Title, Form, Text } from 'uisos';
import AppBox from '../components/AppBox';
import { FormPage } from '../components/FormPage';
import { RegisterForm } from '../components/RegisterForm';

export function Register() {
  return (
    <Grid as="main" gap={0}>
      <FormPage
        title={
          <Title level={4} color="blue">
            Crie uma conta
          </Title>
        }
      >
        <RegisterForm />
      </FormPage>
    </Grid>
  );
}
