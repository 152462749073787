import { CustomerModel, Step } from '../@types/api';

export default ({ model, fields }: Step, customer: CustomerModel) => {
  if (model === 'customer') {
    const a = !fields.some(({ name, required }) => {
      if (required && !customer[name as keyof typeof customer]) {
        return true;
      }
      return false;
    });
    return a;
  }
  return false;
};
