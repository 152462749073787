import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', 'Roboto';
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  body {
     min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    height: -webkit-fill-available;
  }
/*
  bod*::-webkit-scrollbar {
    width: 10px !important;
  } */

  /* *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: transparent; */
  /* } */

  @media (min-width: 768px) {
    *::-webkit-scrollbar,
    *::-webkit-scrollbar-thumb {
      width: 15px;
      border-radius: 13px;
      background-clip: padding-box;
      border: 4px solid transparent;
    }

    *::-webkit-scrollbar-thumb {
      background-color: rgba(142,142,142, 0.5);

      transition: all 0.5s ease;

      &:hover {
        transition: all 0.5s ease;
        background-color: rgba(142,142,142, 0.3);
      }
    }
  }


  .rtds-slider-styles, .rtds-slider-wrapper {
      height: 100% !important;
  }

  .rtds-single-slide-styles {
      height: fit-content !important;

    & > div:first-child {
      padding: 0 !important;
      height: fit-content !important;
    }
  }
`;
