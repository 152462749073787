import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box, Grid, Row, Title, Text, Button } from 'uisos';
import AppBox from '../components/AppBox';
import { FormPage } from '../components/FormPage';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { AuthContext } from '../context/AuthContext';
import { ProfileContext } from '../context/ProfileContext';
import useMediaQuery from '../hooks/useMediaQuery';

export function ResetPassword() {
  const { redirect } = useContext(AuthContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(768);

  return (
    <>
      {redirect ? (
        <Grid as="main" gap={0} style={{ height: '100vh' }}>
          <FormPage
            title={
              <>
                <Title level={4} color="blue">
                  Pronto!
                </Title>
                <Text size="m">
                  Sua senha foi alterada com sucesso! Agora você já pode aproveitar da sua conta
                  IASOS!
                </Text>
                <Button
                  full
                  size="large"
                  color="secondary"
                  onClick={() => navigate(redirect)}
                  style={{ marginTop: 32 }}
                >
                  Prosseguir com {redirect.includes('register') ? 'Registro' : 'Login'}
                </Button>
              </>
            }
          />
        </Grid>
      ) : (
        <Grid as="main" gap={0} style={{ height: '100vh' }}>
          <FormPage
            title={
              <Title level={4} color="blue">
                Crie sua nova senha
              </Title>
            }
          >
            <ResetPasswordForm />
          </FormPage>
        </Grid>
      )}
    </>
  );
}
