import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Row, Form, Text, Input } from 'uisos';
import { AuthContext } from '../../context/AuthContext';
import { ErrorMessage } from '../ErrorMessage';

interface ForgotPasswordFormProps {}

export interface ForgotPasswordData {
  email: string;
}

export function ForgotPasswordForm({}: ForgotPasswordFormProps) {
  const { register, handleSubmit, setValue } = useForm<ForgotPasswordData>();
  const { handleForgotPassword, loading, error, sendedEmail } = useContext(AuthContext);

  useEffect(() => {
    if (sendedEmail) {
      setValue('email', sendedEmail);
    }
  }, [sendedEmail]);

  return (
    <Form onSubmit={handleSubmit(handleForgotPassword as SubmitHandler<ForgotPasswordData>)}>
      <Row>
        <Input
          size="large"
          {...register('email')}
          placeholder="Email"
          helper="Enviaremos os próximos passos no e-mail informado para a recuperação da sua conta."
          error={error && error.key === 'email' ? error?.message : ''}
        >
          Email
        </Input>
      </Row>
      <Row>
        <ErrorMessage>{error && !error.key ? error?.message : ''}</ErrorMessage>
      </Row>
      <Row>
        <Button
          type="submit"
          full
          size="large"
          color="secondary"
          loading={loading}
          disabled={!!sendedEmail}
          style={sendedEmail ? { backgroundColor: '#2f989680' } : {}}
        >
          {sendedEmail ? 'Enviado' : 'Enviar'}
        </Button>
      </Row>
      {sendedEmail && (
        <button
          type="submit"
          style={{ backgroundColor: 'transparent', border: 'none', outline: 'none' }}
        >
          <Text textAlign="center" underline style={{ cursor: 'pointer', marginTop: 16 }}>
            Não recebi o email
          </Text>
        </button>
      )}
    </Form>
  );
}
