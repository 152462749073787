import { useContext } from 'react';
import { IoClose } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import { Box, Title } from 'uisos';
import { AuthContext } from '../../context/AuthContext';
import getModels from '../../data/getModels';
import useMediaQuery from '../../hooks/useMediaQuery';
import CustomLink from '../CustomLink';
import { Desktop } from './desktop';
import { MenuBox, ModelLink, ModelLinks, SubModelLink } from './styles';

interface MenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function Menu({ open, setOpen }: MenuProps) {
  const models = getModels();
  const { handleLogout } = useContext(AuthContext);
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(768);

  const href = (route: string) => {
    return '/user/profile/' + route;
  };

  return (
    <>
      {isMobile ? (
        <MenuBox
          width="60vw"
          height="100vh"
          direction="column"
          padding={[16, 32]}
          justify="flex-start"
          className={open ? 'open' : ''}
        >
          <Box width="100%" height="fit-content" justify="flex-end">
            <IoClose
              size={42}
              onClick={() => setOpen(false)}
              aria-hidden="true"
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Box width="100%" height="fit-content" align="flex-start" margin={[0, 0, 96]}>
            <ModelLinks>
              <ModelLink onClick={() => setOpen(false)}>
                <CustomLink reloadDocument to={'/user/profile'}>
                  <Title level={5} weight={'/user/profile' === pathname ? 'bold' : 'regular'}>
                    Início
                  </Title>
                </CustomLink>
              </ModelLink>
              {models?.map((model) => (
                <Box width="100%" direction="column" key={model.route} align="flex-start">
                  <ModelLink onClick={() => setOpen(false)}>
                    <CustomLink reloadDocument to={href(model.route)}>
                      <Title level={5} weight={href(model.route) === pathname ? 'bold' : 'regular'}>
                        {model.label}
                      </Title>
                    </CustomLink>
                  </ModelLink>
                  {model?.models?.map((submodel) => (
                    <SubModelLink key={submodel.title} onClick={() => setOpen(false)}>
                      <CustomLink reloadDocument to={href(`${model.route}/${submodel.route}`)}>
                        <Title
                          level={6}
                          weight={
                            href(`${model.route}/${submodel.route}`) === pathname
                              ? 'bold'
                              : 'regular'
                          }
                        >
                          {submodel.label}
                        </Title>
                      </CustomLink>
                    </SubModelLink>
                  ))}
                </Box>
              ))}
              <ModelLink onClick={handleLogout}>
                <Title
                  level={5}
                  color="red"
                  weight={'/login/profile' === pathname ? 'bold' : 'regular'}
                >
                  Sair
                </Title>
              </ModelLink>
            </ModelLinks>
          </Box>
        </MenuBox>
      ) : (
        <Desktop open={open} setOpen={setOpen} />
      )}
    </>
  );
}
