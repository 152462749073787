import { ReactNode } from 'react';
import { Box, Row, Title } from 'uisos';
import useMediaQuery from '../../hooks/useMediaQuery';
import AppBox from '../AppBox';

interface FormPageProps {
  title: ReactNode;
  children?: ReactNode;
  subtitle?: ReactNode;
}

export function FormPage({ title, subtitle, children }: FormPageProps) {
  const isMobile = useMediaQuery(768);

  return (
    <>
      {isMobile ? (
        <>
          <Row
            full={!!children}
            style={{ height: '100%', gridRow: !children ? '1 / span 2' : '' }}
            align={!children ? 'center' : 'stretch'}
          >
            <Box
              height={!children ? '30%' : '100%'}
              width={'100%'}
              padding={[!children ? 42 : 80, 24, 42]}
              direction={subtitle || !children ? 'column' : 'row'}
              align={subtitle || !children ? 'flex-start' : 'center'}
            >
              {title}
              {subtitle}
            </Box>
          </Row>
          {children && (
            <Row full style={{ height: '100%' }}>
              <AppBox
                height={'100%'}
                color="blue"
                width={'100%'}
                padding={[56, 24, 0]}
                align="flex-start"
              >
                {children}
              </AppBox>
            </Row>
          )}
        </>
      ) : (
        <Box
          width="100vw"
          direction="column"
          height="100vh"
          align="center"
          justify="center"
          style={{ backgroundColor: 'rgb(31, 109, 109, 0.3)' }}
        >
          <Box
            direction="column"
            width="60%"
            height="80%"
            style={{
              boxShadow: '5px 5px 15px rgba(0,0,0,0.2)',
              backgroundColor: 'white',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <Box height={!children ? '100%' : '30%'} width={!children ? '80%' : '100%'}>
              <Box
                height={'100%'}
                width={'100%'}
                padding={[80, 24, 42]}
                direction={subtitle || !children ? 'column' : 'row'}
                align={subtitle || !children ? 'flex-start' : 'center'}
                justify="center"
              >
                {title}
                {subtitle}
              </Box>
            </Box>
            {children && (
              <Box height="70%" width="100%">
                <AppBox
                  height={'100%'}
                  color="blue"
                  width={'100%'}
                  padding={[56, 24, 0]}
                  align="flex-start"
                >
                  {children}
                </AppBox>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
