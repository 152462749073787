import { createContext, ReactNode } from 'react';
import useModal, { ModalType } from '../hooks/useModal';

interface ModalProviderProps {
  children: ReactNode;
}

interface ModalContextType {
  isOpen: boolean;
  type: string;
  closeModal: () => void;
  openModal: (type: ModalType) => void;
}

const ModalContext = createContext<ModalContextType>({
  isOpen: false,
  type: '',
  closeModal: () => null,
  openModal: (type: ModalType) => null,
});

function ModalProvider({ children }: ModalProviderProps) {
  const { isOpen, type, closeModal, openModal } = useModal();

  return (
    <ModalContext.Provider value={{ isOpen, type, closeModal, openModal }}>
      {children}
    </ModalContext.Provider>
  );
}

export { ModalContext, ModalProvider };
