import { useContext, useEffect } from 'react';
import { Text, Section, Row, Card, Col, Box } from 'uisos';
import { ProfileContext } from '../../context/ProfileContext';
import isEmpty from '../../helpers/isEmpty';
import { DataCards } from '../DataCards';
import { ModelIcon } from '../ModelIcon';
import getDataFromCustomer from '../../helpers/getDataFromCustomer';
import { CustomerModel } from '../../@types/api';
import CustomLink from '../CustomLink';

export function Desktop() {
  const { models, model, customer } = useContext(ProfileContext);

  if (models && models.length > 0) {
    return (
      <Section color="light" style={{ padding: '32px 0', height: '100%', marginLeft: 88 }}>
        <Row justify="flex-start">
          {models.map((model) => (
            <Col span={4} key={model.label}>
              <CustomLink to={model.route} style={{ width: '100%', padding: 4 }}>
                <Card style={{ width: '100%', height: 200, padding: 16 }} hover>
                  <Box direction="column" width="100%" height="100%" justify="space-evenly">
                    <ModelIcon route={model.route} size={57} />
                    <Text size="m" textAlign="center" style={{ marginTop: 8 }}>
                      {model.label}
                    </Text>
                  </Box>
                </Card>
              </CustomLink>
            </Col>
          ))}
        </Row>
      </Section>
    );
  } else if (model && !isEmpty(model) && customer && model.model) {
    const modelData = getDataFromCustomer(customer, model.model as keyof CustomerModel);

    return modelData && (modelData.length || !isEmpty(modelData)) ? (
      <Section color="light" style={{ padding: '32px 0', height: '100%', marginLeft: 88 }}>
        <Row>
          {modelData.length ? (
            modelData.map((data: any, index: number) => (
              <Col span={12} key={index}>
                <DataCards data={data} type={model.model as string} />
              </Col>
            ))
          ) : (
            <Col span={12}>
              <DataCards data={modelData} type={model.model as string} />
            </Col>
          )}
        </Row>
      </Section>
    ) : (
      <Section color="light" style={{ padding: '32px 0', height: '100%' }}>
        <Row></Row>
      </Section>
    );
  } else {
    return null;
  }
}
