import { useContext, useEffect } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Input, Radio, RadioGroup, Select, Box, TextArea, FileInput, Row } from 'uisos';
import { Step } from '../../@types/api';
import mask from '../../helpers/masks';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../ErrorMessage';
import { StepContext } from '../../context/StepContext';
import useMasks from '../../hooks/useMasks';

export interface StepFormProps {
  step: Step;
  error?: {
    message?: string;
    key?: string;
  };
  register: UseFormRegister<StepData>;
  watch: (names?: string | string[] | ((data: any, options: any) => void)) => {
    [key: string | number]: any;
  };
  setValue: UseFormSetValue<StepData>;
}

export interface StepData {
  [key: string]: any;
}

export function StepForm({
  error = { key: '', message: '' },
  step,
  register,
  watch,
  setValue,
}: StepFormProps) {
  const { fields } = step;
  const { key, message } = error;
  const fileName = watch('avatar');
  const organDonorValue = watch('organDonor') as unknown as string | undefined;
  const smokerValue = watch('smoker') as unknown as string | undefined;

  const { model } = useContext(StepContext);
  const { t } = useTranslation();

  useMasks(watch, setValue, fields);

  return (
    <Box direction="column" width="100%">
      {fields.map((field, index) => {
        if (field.type === 'select') {
          return (
            <Select
              label={field.label}
              placeholder={field.placeholder}
              key={index}
              size="large"
              required={model?.length ? false : field.required}
              {...register(field.name)}
              name={field.name}
              error={key === field.name && message ? t(message) : ''}
            >
              {field.options?.map(({ value, label }, index) => (
                <option key={`${label}-${index}`} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          );
        } else if (field.type === 'checkbox') {
          return <></>;
        } else if (field.type === 'radio') {
          return (
            <RadioGroup key={index} label={field.label}>
              {field.radios?.map(({ label, ...radio }, index) => (
                <Radio
                  {...radio}
                  checked={
                    field.name === 'smoker'
                      ? smokerValue === radio.value || smokerValue === ''
                      : field.name === 'organDonor'
                      ? organDonorValue === radio.value || organDonorValue === ''
                      : undefined
                  }
                  value={radio.value || ''}
                  required={field.required}
                  {...register(field.name)}
                  key={index}
                  size="large"
                  name={field.name}
                >
                  {label}
                </Radio>
              ))}
            </RadioGroup>
          );
        } else if (field.type === 'textArea') {
          return (
            <TextArea
              error={key === field.name && message ? t(message) : ''}
              size="large"
              key={index}
              placeholder={field.placeholder}
              {...register(field.name)}
              helper={field.helper}
              required={model?.length ? false : field.required}
            >
              {field.label}
            </TextArea>
          );
        } else if (field.type === 'file') {
          return (
            <>
              <FileInput
                key={index}
                {...register(field.name)}
                file={fileName && fileName[0]}
                label={field.label}
              />
              {key === field.name && message ? <ErrorMessage>{t(message)}</ErrorMessage> : ''}
            </>
          );
        } else if (field.type === 'date') {
          return (
            <Input
              min="1900-01-01"
              error={key === field.name && message ? t(message) : ''}
              type={field.type}
              key={index}
              size="large"
              {...register(field.name)}
              placeholder={field.placeholder}
              helper={field.helper}
              required={field.required}
            >
              {field.label}
            </Input>
          );
        } else {
          return (
            <Input
              inputMode={field.inputMode && field.inputMode}
              error={key === field.name && message ? t(message) : ''}
              type={field.type}
              key={index}
              size="large"
              {...register(field.name)}
              placeholder={field.placeholder}
              helper={field.helper}
              required={model?.length ? false : field.required}
            >
              {field.label}
            </Input>
          );
        }
      })}
      {message && !key ? (
        <Row>
          <ErrorMessage>{t(message)}</ErrorMessage>
        </Row>
      ) : null}
    </Box>
  );
}
