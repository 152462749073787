import styled from 'styled-components';
import { Box } from 'uisos';

export const MenuBox = styled(Box)`
  position: fixed;
  /* background-color: #eef7f7; */
  background-color: #ffffff;
  z-index: 999;
  transform: translateX(-100%);
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  overflow: auto;
  box-sizing: border-box;
  &.open {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transform: translateX(0);
  }
`;

export const IconBg = styled.div`
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: transparent;

  &.selected {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: #b60606;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);

    &:hover {
      background-color: rgb(182, 7, 6, 0.8);
    }

    svg {
      color: white !important;
    }
  }
`;

export const MenuBoxDesktop = styled(Box)`
  position: fixed;
  background-color: #f4f4f4;
  z-index: 999;
  width: 88px;
  transition: width ease 0.4s;
  -webkit-transition: width ease 0.4s;

  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  &.open {
    width: 370px;
    max-width: 370px;

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);

    transition: width ease-in-out 0.4s;
    -webkit-transition: width ease-in-out 0.4s;
    transform: translateX(0);
  }
`;

export const ModelLinks = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-between;
  padding-left: 0;
  width: 100%;
`;

export const ModelLink = styled.li`
  list-style: none;
  padding: 16px 0;
  cursor: pointer;
`;

export const SubModelLink = styled.li`
  list-style: none;
  margin-left: 16px;
  padding: 16px 0;
  cursor: pointer;
`;
