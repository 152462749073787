import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Form, Text, Input } from 'uisos';
import { AuthContext } from '../../context/AuthContext';
import { ModalContext } from '../../context/ModalContext';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ErrorMessage } from '../ErrorMessage';

interface LoginFormProps {}

export interface LoginData {
  email: string;
  password: string;
}

export function LoginForm({}: LoginFormProps) {
  const { register, handleSubmit } = useForm<LoginData>();
  const { handleLogin, error } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(768);

  useEffect(() => {
    if (error && error.message && ['Usuário negado', 'User is legacy'].includes(error.message)) {
      openModal('info');
    }
  }, [error, openModal]);

  return (
    <Form onSubmit={handleSubmit(handleLogin as SubmitHandler<LoginData>)}>
      <Row>
        <Input
          size="large"
          {...register('email')}
          placeholder="Email"
          error={error && error.key === 'email' ? error?.message : ''}
        >
          Email
        </Input>
      </Row>
      <Row>
        <Input
          size="large"
          type="password"
          {...register('password')}
          placeholder="Password"
          helper="Digite sua senha com 8 digitos ou mais"
          error={error && error.key === 'password' ? error?.message : ''}
          toggleSuffix={<FaEye />}
          toggleOffSuffix={<FaEyeSlash />}
        >
          Senha
        </Input>
      </Row>
      <Link
        reloadDocument
        to={'/forgot?redirect=' + encodeURIComponent(pathname)}
        style={{ width: 'fit-content' }}
      >
        <Text
          link
          textAlign="left"
          size={isMobile ? 's' : 'xs'}
          underline
          style={{ cursor: 'pointer', marginTop: 8 }}
        >
          Esqueci a minha senha
        </Text>
      </Link>
      <Row>
        <ErrorMessage>{error && !error.key ? error?.message : ''}</ErrorMessage>
      </Row>
      <Row>
        <Button type="submit" full size="large" color="secondary" style={{ marginTop: 32 }}>
          Entrar
        </Button>
      </Row>
      {!pathname.includes('profile') && (
        <Link reloadDocument to="/register">
          <Text
            link
            size="s"
            textAlign="center"
            underline
            style={{ cursor: 'pointer', marginTop: 16 }}
          >
            Criar conta
          </Text>
        </Link>
      )}
    </Form>
  );
}
