import { Box, Card, Row, Title } from 'uisos';
import { ContactItem } from './ContactItem';
import { CardItem } from './CardItem';
import { Section } from '../../data/getSections';
import { CardContext } from '../../context/CardContext';
import { useContext } from 'react';
import getDataFromCustomer from '../../helpers/getDataFromCustomer';
import { CustomerModel } from '../../@types/api';
import isEmpty from '../../helpers/isEmpty';

export function CardSection({ title, subtitle, model, item, color }: Section) {
  const { cardData } = useContext(CardContext);
  const modelData = cardData.card[model as keyof typeof cardData.card];

  if (modelData && Array.isArray(modelData) && modelData.length) {
    return model === 'contacts' ? (
      <Row full>
        <Box padding={[24, 24, 0]} align="center" height={'fit-content'} width={'100%'}>
          <Card padding={[15, 10, 15, 10]} color="grey" width={'100%'} justify="space-between">
            <Box width={'100%'} justify="center">
              <Title level={6}>{title}</Title>
            </Box>
            <Box width={'100%'} justify="center">
              <Title level={5} style={{ color: '#F54041' }}>
                {subtitle}
              </Title>
            </Box>
            <Box width={'100%'} justify="center" direction="column">
              {modelData.map((section: any) => (
                <ContactItem
                  name={section[item.title]}
                  relationship={section[item.content]}
                  telephone={section[item.telephone || 'telephone']}
                  key={section[item.title]}
                />
              ))}
            </Box>
          </Card>
        </Box>
      </Row>
    ) : (
      <Row full>
        <Box padding={[24, 24, 0]} align="center" height={'fit-content'} width={'100%'}>
          <Card padding={[15, 10, 15, 10]} width={'100%'} justify="space-between">
            <Box width={'100%'} justify="center">
              <Title level={6}>{title}</Title>
            </Box>
            <Box width={'100%'} justify="center" direction="column">
              {modelData.map((section: any) => (
                <CardItem
                  color={color}
                  model={model}
                  item={item}
                  data={section}
                  title={section[item.title]}
                  content={section[item.content]}
                  key={section[item.title]}
                />
              ))}
            </Box>
          </Card>
        </Box>
      </Row>
    );
  } else if (modelData && !Array.isArray(modelData) && typeof modelData === 'object') {
    return !isEmpty(modelData) ? (
      <Row full>
        <Box padding={[24, 24, 0]} align="center" height={'fit-content'} width={'100%'}>
          <Card padding={[15, 10, 15, 10]} width={'100%'} justify="space-between">
            <Box width={'100%'} justify="center">
              <Title level={6}>{title}</Title>
            </Box>
            <Box width={'100%'} justify="center" direction="column">
              {Object.entries(modelData)
                .slice(0, -2)
                .map(([title, content]) => (
                  <CardItem
                    color={color}
                    model={model}
                    title={title}
                    content={content}
                    key={title}
                  />
                ))}
            </Box>
          </Card>
        </Box>
      </Row>
    ) : null;
  }
  return null;
}
