import { useContext, useEffect, useState } from 'react';
import { Box, Title, Text, Section, Row, Col } from 'uisos';
import shortName from '../../helpers/shortName';
import { IoMdWater } from 'react-icons/io';
import { MdCake } from 'react-icons/md';
import { Avatar } from './styles';
import { ProfileContext } from '../../context/ProfileContext';
import { FaCamera } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Desktop } from './desktop';
import ReactLoading from 'react-loading';

export function ProfileHero() {
  const [avatar, setAvatar] = useState<any>();
  const { t } = useTranslation();
  const { customer, loading, updateAvatar, error, setError } = useContext(ProfileContext);
  const isMobile = useMediaQuery(768);

  useEffect(() => {
    if (avatar && avatar[0]) {
      updateAvatar(avatar);
    }
  }, [avatar]);

  useEffect(() => {
    if (error && error.message && error.key === 'avatar') {
      alert(t(error.message));
      setAvatar(null);
      setError({ message: '', key: '' });
    }
  }, [error]);

  return customer ? (
    <>
      {isMobile ? (
        <Section color="light" style={{ padding: '32px 0 0', minHeight: 171 }}>
          <Row>
            <Col span={5}>
              <Box width="fit-content" height="fit-content" style={{ position: 'relative' }}>
                {loading ? (
                  <ReactLoading type="spin" color="#269898" width={'100%'} height={'100%'} />
                ) : (
                  <Avatar
                    name={'avatar'}
                    onChange={({ target }) => setAvatar(target.files)}
                    icon={<FaCamera size={32} className="icon" />}
                    file={
                      avatar
                        ? avatar[0]
                        : `${process.env.REACT_APP_API_BASE_URL}/files/${
                            customer.avatar || process.env.DEFAULT_AVATAR
                          }`
                    }
                  />
                )}
              </Box>
            </Col>
            <Col span={7} justify="flex-start">
              <Box
                direction="column"
                align="flex-start"
                margin={[0, 0, 0, 18]}
                height="100%"
                width="100%"
                justify="space-evenly"
              >
                <Title level={5}>{shortName(customer.full_name)}</Title>
                <Box width={'100%'} style={{ flexWrap: 'wrap' }}>
                  {customer.healthData?.blood_type ? (
                    <Box width={'50%'} margin={[0, 0, 18, 0]} align="center" justify="flex-start">
                      <IoMdWater size={24} style={{ marginRight: 6 }} />
                      <Text size="xs">{customer?.healthData?.blood_type}</Text>
                    </Box>
                  ) : null}
                  {typeof customer.age === 'number' ? (
                    <Box width={'50%'} margin={[0, 0, 18, 0]} align="center" justify="flex-start">
                      <MdCake size={28} style={{ marginRight: 6 }} />
                      <Text size="xs">
                        {customer.age} ano{customer.age === 1 ? '' : 's'}
                      </Text>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Col>
          </Row>
        </Section>
      ) : (
        <Desktop />
      )}
    </>
  ) : null;
}
