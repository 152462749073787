import { useEffect } from 'react';
import { Box, Button, Col, Grid, Input, Row, Title, Form, Text } from 'uisos';
import AppBox from '../components/AppBox';
import { FormPage } from '../components/FormPage';
import { ValidationForm } from '../components/ValidationForm';
import api from '../services/api';

export function Validation() {
  useEffect(() => {
    (async () => {
      await api.post(`/user/verify`);
    })();
  }, []);

  return (
    <Grid as="main" gap={0} style={{ height: '100vh' }}>
      <FormPage
        title={
          <Title level={4} color="blue">
            Vamos verificar sua conta
          </Title>
        }
        subtitle={<Text size="m">Enviamos um código de validação para o email informado</Text>}
      >
        <ValidationForm />
      </FormPage>
    </Grid>
  );
}
