import React, { ReactNode } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';

export default function CustomLink({ to, children, ...props }: LinkProps) {
  const navigate = useNavigate();
  return (
    <Link
      to={to}
      onClick={(e) => {
        e.preventDefault();
        setTimeout(() => navigate(to), 300);
      }}
      {...props}
    >
      {children}
    </Link>
  );
}
