import { createContext, ReactNode } from 'react';
import { CustomerModel } from '../@types/api';
import { ForgotPasswordData } from '../components/ForgotPasswordForm';
import { LoginData } from '../components/LoginForm';
import { RegisterData } from '../components/RegisterForm';
import { ResetPasswordData } from '../components/ResetPasswordForm';
import { ValidationData } from '../components/ValidationForm';
import useAuth from '../hooks/useAuth';

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType {
  authenticated?: boolean;
  // customer?: CustomerModel;
  handleLogin?: (login: LoginData) => Promise<void>;
  handleLogout?: () => void;
  handleRegister?: (register: RegisterData) => Promise<void>;
  handleForgotPassword?: (data: ForgotPasswordData) => Promise<void>;
  handleReset?: (data: ResetPasswordData, e: any) => Promise<void>;
  handleValidation?: (data: ValidationData) => Promise<void>;
  resendCode?: () => Promise<void>;
  setError: (data: any) => void;
  loading?: boolean;
  resending?: boolean;
  error?: { [key: string]: any };
  sendedEmail?: string;
  redirect?: string;
  emailValidated?: boolean;
}

const AuthContext = createContext<AuthContextType>({
  setError: (data: any) => null,
});

function AuthProvider({ children }: AuthProviderProps) {
  const {
    handleLogin,
    handleRegister,
    handleForgotPassword,
    authenticated,
    loading,
    error,
    handleLogout,
    sendedEmail,
    handleReset,
    redirect,
    handleValidation,
    resendCode,
    resending,
    emailValidated,
    setError,
    // customer,
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        handleReset,
        sendedEmail,
        authenticated,
        handleLogin,
        handleRegister,
        setError,
        loading,
        error,
        handleForgotPassword,
        handleLogout,
        redirect,
        handleValidation,
        resendCode,
        resending,
        emailValidated,
        // customer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
