import {
  FaAccessibleIcon,
  FaBlind,
  FaDeaf,
  FaDisease,
  FaRibbon,
  FaPlusSquare,
  FaTint,
  FaHeadSideMask,
} from 'react-icons/fa';
import { GiFat } from 'react-icons/gi';

interface ConditionIconProps {
  name: string;
  type: string;
  size: number;
  color?: string;
}

export function ConditionIcon({ name, type, size, color = '#000' }: ConditionIconProps) {
  if (['cego', 'cega', 'cegueira'].includes(name.toLowerCase())) {
    return <FaBlind size={size} color={color} />;
  } else if (['surdo', 'surda', 'surdez', 'surdo'].includes(name.toLowerCase())) {
    return <FaDeaf size={size} color={color} />;
  } else if (
    ['hipertensão', 'hipertensao', 'hipertenso', 'hipertensa'].includes(name.toLowerCase())
  ) {
    return <FaTint size={size} color={color} />;
  } else if (['obesidade', 'obeso', 'obesa'].includes(name.toLowerCase())) {
    return <GiFat size={size} color={color} />;
  } else if (type === 'deficiency') {
    return <FaAccessibleIcon size={size} color={color} />;
  } else if (type === 'comorbity') {
    return <FaHeadSideMask size={size} color={color} />;
  } else if (type === 'disease') {
    return <FaDisease size={size} color={color} />;
  } else if (type === 'syndrome') {
    return <FaRibbon size={size} color={color} />;
  } else {
    return <FaPlusSquare size={size} color={color} />;
  }
}
