import moment from 'moment';
import { CustomerModel } from '../@types/api';

export default (customer: CustomerModel, model: keyof CustomerModel) => {
  if (customer[model]) {
    if (model === 'healthData') {
      const { birth_date, full_name, address } = customer;
      const { created_at, updated_at, id, customer_id, ...data } = customer[model];

      const modelData: { [key: string]: any } = {
        full_name,
        address: address?.street || '',
        birth_date:
          moment((birth_date as unknown as string)?.split('T')[0]).format('MM/DD/YYYY') || '',
        ...data,
      };

      return modelData;
    } else if (model === 'user') {
      const { cpf, telephone, notification, rg } = customer;
      const { created_at, updated_at, id, role, status, emailValidated, validationCode, ...data } =
        customer[model];
      return {
        ...data,
        rg,
        cpf,
        telephone,
        notification,
      };
    } else if (model === 'address') {
      const { created_at, updated_at, id, ...data } = customer[model];
      return {
        ...data,
      };
    } else {
      return customer[model];
    }
  }
};
